import { useMemo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Cookies from "@/services/cookies";
import { useDispatch } from "react-redux";
import { Tooltip, Zoom } from "@mui/material";
import menuLogo from "@/assets/images/svg/menu.svg";
import { profileMenuItems } from "@/constants/ConstantConfig";
import { logout, setPageLoader } from "@/modules/login/slice/loginSlice";
import routesConstants from "@/routes/routesConstants";
import { useSelector } from "react-redux";
import { BlueUserIcon, DummyImg } from "@/constants/images";

const Header = ({ isOpen, toggleSidebar }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { profileDetail } = useSelector((state) => ({
    profileDetail: state?.profile?.profileDetail,
  }));

  const User = useMemo(
    () => Cookies.get("user") || {},
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pathname, profileDetail]
  );

  //handle get logout
  const handleLogOut = () => {
    //Handle page loader
    dispatch(setPageLoader(true));
    //get logout
    dispatch({ type: "RESET_STORE" });
    dispatch(logout()).then(() => {
      navigate(routesConstants?.LOGIN);
      Cookies.clear();
      dispatch(setPageLoader(false));
    });
  };
  return (
    <header className="header header_block">
      <div className="logo">
        <Tooltip
          title={`${!isOpen ? "Open Menu" : "Close Menu"}`}
          arrow
          TransitionComponent={Zoom}
        >
          <img
            src={menuLogo}
            alt="Logo"
            className="cur_point"
            onClick={toggleSidebar}
          />
        </Tooltip>
      </div>
      <div className="user">
        <div className="dropdown loggedAccount">
          <div
            className=" user border-0 custom-dropdown-toggle cur_point"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {<img
              src={User?.companyLogo ? User?.companyLogo : DummyImg}
              onError={(err) => {
                err.currentTarget.onerror = null;
                err.currentTarget.src = BlueUserIcon;
              }} />}

            <div className="d-flex align-items-start justify-content-start flex-column">
              <span className="username mb-1 ellipse-text">
                {User?.firstName || User?.lastName
                  ? `${User?.firstName} ${User?.lastName}`
                  : ""}
              </span>
              <span className="user_email">{User?.email || ""}</span>
            </div>
            <i className="fa-solid fa-sort-down ms-2 arrow-icon"></i>
          </div>
          <ul
            className="dropdown-menu animate slideIn p-2"
            aria-labelledby="dropdownMenuButton1"
          >
            {profileMenuItems?.map((item, index) => (
              <li
                key={index}
                className={item?.text === "Logout" ? "" : "border-bottom"}
              >
                <Link
                  className="dropdown-item accountSettings"
                  to={item?.link}
                  onClick={item?.text === "Logout" ? handleLogOut : () => { }}
                >
                  {item?.text}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </header>
  );
};
export default Header;
