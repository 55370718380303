// Service
export const ServiceNameRequired = "Please enter service name.";
export const hoursRequired = "Please enter hours.";
export const minutesRequired = "Please enter minutes.";
export const HoursMustBeGreater = "Hours must be greater than or equal to 0.";
export const MinutesValidation = "Minutes must be between 0 and 59.";
export const HoursValidation =
  "If hours is 00, minutes should be more than 00.";

export const SkillNameRequired = "Please enter title.";
export const CompanyNameRequired = "Please enter company name.";
export const FirstNameRequired = "Please enter first name.";
export const LastNameRequired = "Please enter last name.";
export const EmailRequired = "Please enter email address.";
export const EmailValidation = "Please enter valid email address.";
export const InvalidEmailFormat = "Please enter valid email.";
export const MobileRequired = "Please enter mobile number.";
export const Address1Required = "Please enter address.";
export const TelephoneRequired = "Please enter telephone number.";
export const TelephoneValidation = "Please enter valid telephone number.";
export const MobileValidation = "Please enter valid mobile number.";
export const CityRequired = "Please enter city/town.";
export const CountyRequired = "Please enter county.";
export const StateRequired = "Please enter state.";
export const PostCodeRequired = "Please enter postcode.";
export const TaxCodeRequired = "Please enter tax code.";
export const VATRequired = "Please enter VAT registration number.";
export const CompanyCodeRequired = "Please enter company code.";
export const BusinessRegisterRequired =
  "Please enter business registration number.";
export const TaxIdentificationRequired =
  "Please enter tax identification number.";
export const CompanyTypeRequired = "Please enter company type.";
export const WebsiteValidation = "Please enter valid website url.";
export const CompanyRequired = "Please select company.";
export const MobileNumberRequired = "Please enter mobile number.";
export const PostCodeLimitation = "Postcode must not exceed 50 characters.";
export const CharacterLimitation = "Skill must not exceed 50 characters.";
export const BusinessNameRequired = "Please enter business name.";
export const AccountNumberRequired = "Please enter account number.";
export const NominaleCodeLimit = "Nominal code must be 8 characters.";
export const departmentRequired = "Please select department.";
export const departmentInputRequired = "Please enter department.";
export const companyRequired = "Please select company.";
export const permissionRequired = "Please select permission profile.";
export const positionRequired = "Please enter position.";
export const titleRequired = "Please enter title.";
export const descriptionRequired = "Please enter description.";
export const startDateRequired = "Please enter start date.";

export const OtpRequired = "Please enter verification code.";
export const OtpValidation = "Verification code must be a 6-digit number.";
export const WebsiteLimitation = "Website must not exceed 250 characters.";
export const ImageValidation = "Only image files are allowed.";
export const atLeastOneSkillRequired = "Please select at least one skill.";
export const profileNameRequired = "Please enter profile name.";

// DefaultErrorMessage
export const somethingWentWrong =
  "Something went wrong,please try again later.";
// Maximum limit
export const maximumLimit = "Maximum 250 characters are allowed";
export const maximumLimit50 = "Maximum 50 characters are allowed";

//Change Password
export const passwordRequired = "Please enter password.";
export const oldPassword = "Please enter old password.";
export const newPassword = "Please enter new password.";
export const confirmNewPassword = "Please enter confirm password.";
export const passwordMatch = "Password and confirm password does not match.";
export const PasswordValidation =
  "Use at least 12 characters and a mix of symbols, numbers, upper and lower case letters for a strong password.";
export const maxPasswordLimit = "Password cannot exceed 16 characters.";

//shop
export const shopNameRequired = "Please enter shop name.";
export const shopOutletRequired = "Please enter shop outlet number.";
export const shopOutletLimit = "Shop outlet number cannot exceed 8 digits.";
export const shopTimeRequired = "Please select shop time.";
export const shopTimeLimit = "Please select atleast one shop timing.";
export const openingTimeRequired = "Opening time is required.";
export const closingTimeRequired = "Closing time is required.";
export const openingTimeValidation =
  "Opening time must be smaller than closing time.";
export const closingTimeValidation =
  "Opening and closing time cannot be the same.";

// Stock Movement
export const SerialNumberInUse = "Serial number already in use.";
export const DuplicateSerialNumberNotAllowed =
  "Duplicate serial numbers are not allowed.";
export const NoSelectSameCompany = "Please do not select the same company";

// Delivery notes
export const CustomerNumber = "Please select customer.";
export const OutletNumber = "Please select outlet.";
export const OrderNumber = "Please enter order number.";
export const OrderDate = "Please enter order date.";
export const AdditionalNote = "Please enter note.";
export const InvoiceDateValidation =
  "Invoice date must be after or equal to order date.";
export const ShippedDateValidation =
  "Shipped date must be after or equal to order date.";
export const ReceivedDateValidation =
  "Received date must be after or equal to shipped date.";
export const ReceivedConfirmDateValidation =
  "Received confirmed date must be after or equal to received date.";
export const AtLeastOneDeliveryNoteRequired =
  "Please select at least one delivery note.";

//Customer
export const customerNumberRequired = "Please enter customer number.";

// N/A
export const notAvailable = "N/A";
// Company
export const productCodeRequired = "Please enter product code.";
export const productNameRequired = "Please select product.";
export const quantityRequired = "Please enter at least one quantity.";
export const quantityMinValue = "Quantity must be greater than 0.";
export const serialNumberRequired = "Please enter serial number.";
export const companyCodeMaxLimit =
  "Company code must be at least 3 characters.";
export const productCodeNotFound = "Product code not found";

// Additional Details
export const headerRequired = "Please select header detail.";
export const additionalDetailRequired = "Please enter additional details.";

// Task Templates
export const templateNameRequired = "Please enter template name.";
export const taskTitleRequired = "Please enter task name.";

// Job Module
export const projectSelectRequired = "Please select project.";
export const customerSelectRequired = "Please select customer.";
export const shopSelectRequired = "Please select shop.";
export const serviceSelectRequired = "Please select service.";
export const companySelectRequired = "Please select company.";
export const selectDueDateRequired = "Please select due date.";
export const compareDueDateToScheduleDate =
  "Due date must be greater than or equal to schedule date.";

export const questionRequired = "Please enter a question.";
export const answerRequired = "Please enter an answer.";
export const engineerRequired = "Please select engineer.";
export const supportPersonRequired = "Please select support person.";
export const scheduleDateRequired = "Please select schedule date.";
export const dueDateRequired = "Please select due date.";
export const taskTemplateRequired = "Please select task template.";
export const DateMustBeInPast = "The selected date must not be in the past.";
export const RescheduleReasonRequired = "Please enter reschedule reason.";
export const RescheduleDateRequired = "Please enter reschedule date.";
export const NoPastDate = "You cannot select a past date.";

// Additional details header
export const detailsHeaderRequired = "Please enter details header.";

// Product
export const productNameIsRequired = "Please enter product name.";
export const ManufacturerRequired = "Please enter manufacturer.";
export const ModalRequired = "Please enter model.";
export const InterfaceRequired = "Please enter interface.";
export const AtLeastOneSupplier =
  "Please select at least one preferred supplier.";
export const SerialNoTrackableRequired = "Please select serial no trackable.";
export const TangibleRequired = "Please select tangible.";
export const DefaultTaxCodeRequired = "Please enter default tax code.";

export const CharacterLimitExceed =
  "Character limit exceeded. Max 250 characters allowed.";
