import { Outlet } from "react-router-dom";
import _404 from "@/components/common/_404";
import routesConstants from "./routesConstants";
import Unauthorized from "@/components/common/Unauthorized";
import {
  Dashboard,
  ForgotPassword,
  Login,
  Service,
  Skill,
  ViewService,
  Admins,
  ResetPassword,
  Products,
  ViewProductDetails,
  Companies,
  AddEditCompanies,
  Profile,
  AddEditProduct,
  ViewCompany,
  Engineers,
  EngineerDetails,
  Projects,
  ViewCompanyContactDetails,
  ViewAdmin,
  GoodReceipt,
  AddEditGoodReceipt,
  Templates,
  AddEditTemplate,
  StockMovement,
  AddEditStockMovement,
  Jobs,
  DeliveryNotes,
  Customers,
  FAQs,
  Suppliers,
  AddEditSupplier,
  ViewSupplier,
  AddEditPermission,
  ViewSkill,
  ProjectView,
  TwoFactorAuthentication,
  PermissionProfile,
  ChangePassword,
  ViewTemplate,
  ViewPermission,
  SearchDeliveryNotesItems,
  AddEditDeliveryNotes,
  CustomersPage,
  ViewCustomerPage,
  AddShops,
  ViewShops,
  AddEditJobs,
  ViewJobs,
  AdditionalDetailsMaster,
  AboutUs,
  ActivityLog,
} from "./routeImports";
import { menuIdMap, roles } from "@/constants/ConstantConfig";

const routesConfig = {
  common: [
    {
      path: routesConstants.UNAUTHORIZED,
      component: Unauthorized,
      menuId: 0,
    },
    { path: routesConstants._404, component: _404, menuId: 0 },
  ],
  private: [
    {
      path: routesConstants.HOME,
      component: Dashboard,
      menuId: menuIdMap?.Dashboard,
    },
    {
      path: routesConstants.ADMINS,
      component: Outlet,
      children: [
        { index: true, component: Admins, menuId: menuIdMap?.Admins },
        { path: ":id", component: ViewAdmin, menuId: menuIdMap?.Admins },
      ],
      menuId: menuIdMap?.Admins,
    },
    {
      path: routesConstants.SERVICES,
      component: Outlet,
      children: [
        {
          index: true,
          component: Service,
          menuId: menuIdMap?.Services,
        },
        {
          path: ":id",
          component: ViewService,
          menuId: menuIdMap?.Services,
        },
      ],
      menuId: menuIdMap?.Services,
    },
    {
      path: routesConstants.SKILLS,
      component: Outlet,
      children: [
        { index: true, component: Skill, menuId: menuIdMap?.Skills },
        { path: ":id", component: ViewSkill, menuId: menuIdMap?.Skills },
      ],
      menuId: menuIdMap?.Skills,
    },
    {
      path: routesConstants?.ADDITIONAL_DETAILS_MASTER,
      component: Outlet,
      children: [
        { index: true, component: AdditionalDetailsMaster, menuId: 0 },
      ],
      menuId: 0,
    },
    {
      path: routesConstants.PRODUCTS,
      component: Outlet,
      children: [
        { index: true, component: Products, menuId: menuIdMap?.Products },
        {
          path: ":id",
          component: ViewProductDetails,
          menuId: menuIdMap?.Products,
        },
        {
          path: ":id/mode=edit",
          component: AddEditProduct,
          menuId: menuIdMap?.Products,
        },
        {
          path: "create",
          component: AddEditProduct,
          menuId: menuIdMap?.Products,
        },
      ],
      menuId: menuIdMap?.Products,
    },
    {
      path: routesConstants.PROFILE,
      component: Outlet,
      children: [
        { index: true, component: Profile, menuId: 0 },
        { path: "edit-profile", component: Profile, menuId: 0 },
      ],
      menuId: 0,
    },
    {
      path: routesConstants.CHANGE_PASSWORD,
      component: ChangePassword,
      menuId: 0,
    },
    {
      path: routesConstants.PROJECTS,
      component: Outlet,
      children: [
        { index: true, component: Projects, menuId: menuIdMap?.Projects },
        { path: ":id", component: ProjectView, menuId: menuIdMap?.Projects },
      ],
      menuId: menuIdMap?.Projects,
    },
    {
      path: routesConstants.COMPANIES,
      component: Outlet,
      children: [
        { index: true, component: Companies, menuId: menuIdMap?.Companies },
        { path: ":id", component: ViewCompany, menuId: menuIdMap?.Companies },
        {
          path: ":id/mode=edit",
          component: AddEditCompanies,
          menuId: menuIdMap?.Companies,
        },
        {
          path: "create",
          component: AddEditCompanies,
          menuId: menuIdMap?.Companies,
        },
        {
          path: "contact/:id",
          component: ViewCompanyContactDetails,
          menuId: menuIdMap?.Companies,
        },
      ],
      roles: [roles?.superAdmin, roles?.nisystAdmin],
      menuId: menuIdMap?.Companies,
    },
    {
      path: routesConstants.TEMPLATES,
      component: Outlet,
      children: [
        { index: true, component: Templates, menuId: menuIdMap?.TaskTemplate },
        {
          path: ":id/mode=edit",
          component: AddEditTemplate,
          menuId: menuIdMap?.TaskTemplate,
        },
        {
          path: "create",
          component: AddEditTemplate,
          menuId: menuIdMap?.TaskTemplate,
        },
        {
          path: ":id",
          component: ViewTemplate,
          menuId: menuIdMap?.TaskTemplate,
        },
      ],
      menuId: menuIdMap?.TaskTemplate,
    },
    {
      path: routesConstants.GOODS_RECEIPT,
      component: Outlet,
      children: [
        { index: true, component: GoodReceipt, menuId: menuIdMap?.GoodReceipt },
        {
          path: ":id",
          component: AddEditGoodReceipt,
          menuId: menuIdMap?.GoodReceipt,
        },
        {
          path: ":id/mode=edit",
          component: AddEditGoodReceipt,
          menuId: menuIdMap?.GoodReceipt,
        },
        {
          path: "create",
          component: AddEditGoodReceipt,
          menuId: menuIdMap?.GoodReceipt,
        },
      ],
      menuId: menuIdMap?.GoodReceipt,
    },
    {
      path: routesConstants.ENGINEERS,
      component: Outlet,
      children: [
        { index: true, component: Engineers, menuId: menuIdMap?.Engineers },
        {
          path: ":id",
          component: EngineerDetails,
          menuId: menuIdMap?.Engineers,
        },
      ],
      menuId: menuIdMap?.Engineers,
    },
    {
      path: routesConstants.STOCK_MOVEMENT,
      component: Outlet,
      children: [
        {
          index: true,
          component: StockMovement,
          menuId: menuIdMap?.StockMovements,
        },
        {
          path: "create",
          component: AddEditStockMovement,
          menuId: menuIdMap?.StockMovements,
        },
        {
          path: ":id",
          component: AddEditStockMovement,
          menuId: menuIdMap?.StockMovements,
        },
        {
          path: ":id/mode=edit",
          component: AddEditStockMovement,
          menuId: menuIdMap?.StockMovements,
        },
      ],
      menuId: menuIdMap?.StockMovements,
    },
    {
      path: routesConstants.JOBS,
      component: Outlet,
      children: [
        { index: true, component: Jobs, menuId: menuIdMap?.Jobs },
        { path: "create", component: AddEditJobs, menuId: menuIdMap?.Jobs },
        {
          path: ":id/mode=edit",
          component: AddEditJobs,
          menuId: menuIdMap?.Jobs,
        },
        {
          path: ":id",
          component: ViewJobs,
          menuId: menuIdMap?.Jobs,
        },
      ],
      menuId: menuIdMap?.Jobs,
    },
    {
      path: routesConstants.DELIVERY_NOTES,
      component: Outlet,
      children: [
        {
          index: true,
          component: DeliveryNotes,
          menuId: 0,
        },
        {
          path: routesConstants?.SEARCH,
          component: SearchDeliveryNotesItems,
          menuId: 0,
        },
        {
          path: routesConstants?.CREATE,
          component: AddEditDeliveryNotes,
          menuId: 0,
        },
        {
          path: routesConstants?.VIEW,
          component: AddEditDeliveryNotes,
          menuId: 0,
        },
        {
          path: routesConstants?.SEARCH_VIEW,
          component: AddEditDeliveryNotes,
          menuId: 0,
        },
        {
          path: routesConstants?.EDIT,
          component: AddEditDeliveryNotes,
          menuId: 0,
        },
        {
          path: routesConstants?.SOURCE_JOB,
          component: AddEditDeliveryNotes,
          menuId: 0,
        },
        {
          path: routesConstants?.EDIT_SOURCE_JOB,
          component: AddEditDeliveryNotes,
          menuId: 0,
        },
      ],
      menuId: 0,
    },
    {
      path: routesConstants.CUSTOMERS,
      component: Outlet,
      children: [
        {
          index: true,
          component: Customers,
          menuId: menuIdMap?.Customers,
        },
        {
          path: "create",
          component: CustomersPage,
          menuId: menuIdMap?.Customers,
        },
        {
          path: ":id/mode=edit",
          component: CustomersPage,
          menuId: menuIdMap?.Customers,
        },
        {
          path: ":id",
          component: ViewCustomerPage,
          menuId: menuIdMap?.Customers,
        },
        {
          path: "shops/create",
          component: AddShops,
          menuId: menuIdMap?.Customers,
        },
        {
          path: "shops/:id/mode=edit",
          component: AddShops,
          menuId: menuIdMap?.Customers,
        },
        {
          path: "shops/:id",
          component: ViewShops,
          menuId: menuIdMap?.Customers,
        },
      ],
      menuId: menuIdMap?.Customers,
    },
    {
      path: routesConstants.PERMISSIONS_PROFILES,
      component: Outlet,
      children: [
        {
          index: true,
          component: PermissionProfile,
          menuId: menuIdMap?.PermissionProfile,
        },
        {
          path: "create",
          component: AddEditPermission,
          menuId: menuIdMap?.PermissionProfile,
        },
        {
          path: ":id",
          component: ViewPermission,
          menuId: menuIdMap?.PermissionProfile,
        },
        {
          path: ":id/mode=edit",
          component: AddEditPermission,
          menuId: menuIdMap?.PermissionProfile,
        },
      ],
      roles: [roles?.superAdmin, roles?.nisystAdmin],
      menuId: menuIdMap?.PermissionProfile,
    },
    {
      path: routesConstants.FAQ,
      component: FAQs,
      menuId: 0,
    },
    {
      path: routesConstants?.SUPPLIER,
      component: Outlet,
      children: [
        { index: true, component: Suppliers, menuId: menuIdMap?.Supplier },
        {
          path: "create",
          component: AddEditSupplier,
          menuId: menuIdMap?.Supplier,
        },
        { path: ":id", component: ViewSupplier, menuId: menuIdMap?.Supplier },
        {
          path: ":id/mode=edit",
          component: AddEditSupplier,
          menuId: menuIdMap?.Supplier,
        },
      ],
      menuId: menuIdMap?.Supplier,
    },
    {
      path: routesConstants?.ABOUT_US,
      component: AboutUs,
      
    },
    {
      path: routesConstants.ACTIVITY_LOG,
      component: ActivityLog,
      menuId: 0,
    },
  ],
  public: [
    { path: routesConstants?.LOGIN, component: Login },
    {
      path: routesConstants?.TWO_FACTOR_AUTH,
      component: TwoFactorAuthentication,
    },
    {
      path: routesConstants?.FORGOT_PASSWORD,
      component: ForgotPassword,
    },
    {
      path: routesConstants?.RESET_PASSWORD,
      component: ResetPassword,
    },
  ],
};

export default routesConfig;
