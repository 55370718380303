import { axiosNisystAdmin } from "@/services/api";
import Cookies from "@/services/cookies";
import {
  CONTACT_US_URL,
  GET_DEPARTMENT_INFORMATION,
  GET_PERMISSION_PROFILE_LIST_BY_ID,
  GET_USER_PROFILE,
  UPDATE_USER_PROFILE,
} from "@/services/url";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const initialState = {
  profileDetail: "",
  loading: false,
  departmentList: [],
  adminPermissions: {},
};

export const fetchProfileDetail = createAsyncThunk(
  `profile/fetchProfileDetail`,
  async () => {
    try {
      const response = await axiosNisystAdmin.post(GET_USER_PROFILE);
      Cookies.set("user", response?.data?.data);
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const updateProfileDetail = createAsyncThunk(
  `profile/updateProfileDetail`,
  async (payload) => {
    try {
      const response = await axiosNisystAdmin.post(
        UPDATE_USER_PROFILE,
        payload
      );
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const getDepartmentInformation = createAsyncThunk(
  `profile/getDepartmentInformation`,
  async () => {
    try {
      const response = await axiosNisystAdmin.get(GET_DEPARTMENT_INFORMATION);
      return response;
    } catch (err) {
      return err;
    }
  }
);

//get logged in user permission list
export const getAdminPermissionListById = createAsyncThunk(
  `profile/getAdminPermissionListById`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.post(
        GET_PERMISSION_PROFILE_LIST_BY_ID,
        payload
      );
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

//Contact us api call
export const upsertContactUs = createAsyncThunk(
  `profile/upsertContactUs`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.post(CONTACT_US_URL, payload);
      if (response?.data) {
        toast.success(response?.data?.message);
        return response.data;
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

const ProfileSlice = createSlice({
  name: "profile",
  initialState: initialState,
  reducers: {
    setProfileLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProfileDetail.pending, () => {});
    builder.addCase(fetchProfileDetail.fulfilled, (state, action) => {
      state.loading = false;
      state.profileDetail = action?.payload?.data?.data;
    });
    builder.addCase(fetchProfileDetail.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getDepartmentInformation.pending, () => {});
    builder.addCase(getDepartmentInformation.fulfilled, (state, action) => {
      state.departmentList = action.payload.data?.data?.department;
    });
    builder.addCase(getDepartmentInformation.rejected, () => {});
    //get logged in user permission list
    builder.addCase(getAdminPermissionListById.pending, () => {});
    builder.addCase(getAdminPermissionListById.fulfilled, (state, action) => {
      state.adminPermissions = action?.payload?.data;
    });
    builder.addCase(getAdminPermissionListById.rejected, () => {});
  },
});
export const { setProfileLoading } = ProfileSlice.actions;
export default ProfileSlice.reducer;
