const conf = {
  APIUrl: String(import.meta.env.VITE_REACT_APP_API_URL),
  importUrl: String(import.meta.env.VITE_REACT_APP_IMPORT_URL),
  cookiePath: String(import.meta.env.VITE_REACT_APP_COOKIE_PATH),
  cookieDomain: String(import.meta.env.VITE_REACT_APP_COOKIE_DOMAIN),
  cookieExpires: String(import.meta.env.VITE_REACT_APP_COOKIE_EXPIRES),
  superAdminProfile: String(
    import.meta.env.VITE_REACT_APP_SUPER_ADMIN_PERMISSION
  ),
  imagePublicUrl: String(import.meta.env.VITE_REACT_APP_IMAGE_PUBLIC_URL),
  encryptionKey: String(import.meta.env.VITE_REACT_APP_ENCRYPTION_KEY),
  encryptionIV: String(import.meta.env.VITE_REACT_APP_ENCRYPTION_IV),
};
export default conf;
