import { toast } from "react-toastify";
import Cookies, { cookieKeys } from "@/services/cookies";
import { axiosNisystAdmin } from "@/services/api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { somethingWentWrong } from "@/constants/SchemaValidation";
import {
  CHANGE_PASSWORD,
  FORGOT_PASSWORD,
  LOGIN,
  LOGOUT,
  RESEND_AUTH,
  RESET_PASSWORD,
  VERIFY_AUTH,
} from "@/services/url";

const token = Cookies.get(cookieKeys?.TOKEN);
const loginState = {
  isAuth: !!token,
  loading: false,
  token: token ? token : "",
  error: null,
  pageLoader: false,
};

export const getLogin = createAsyncThunk(
  `authentication/LoginThunk`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.post(LOGIN, payload);
      Cookies.set("user", response?.data?.data);
      Cookies.set("userId", response?.data?.data?.userId);
      return response.data;
    } catch (err) {
      toast.error(err?.response?.data?.message || somethingWentWrong);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

export const logout = createAsyncThunk(
  `authentication/LogoutThunk`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.get(LOGOUT, payload);
      return response.data;
    } catch (err) {
      toast.error(err?.response?.data?.message || somethingWentWrong);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

export const getVerifyAuth = createAsyncThunk(
  `authentication/VerifyEmailThunk`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.post(VERIFY_AUTH, payload);
      Cookies.set("user", response?.data?.data);
      return response.data;
    } catch (err) {
      toast.error(err?.response?.data?.message || somethingWentWrong);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

export const getResendVerifyAuth = createAsyncThunk(
  `authentication/ResendVerifyEmailThunk`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.post(RESEND_AUTH, payload);
      toast.success(response?.data?.message);
      return response.data;
    } catch (err) {
      toast.error(err?.response?.data?.message || somethingWentWrong);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

export const changePasswordAdmin = createAsyncThunk(
  `authentication/changePasswordAdmin`,
  async (payload) => {
    try {
      const response = await axiosNisystAdmin.post(CHANGE_PASSWORD, payload);
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const forgetPasswordAdmin = createAsyncThunk(
  `authentication/forgetPasswordAdmin`,
  async (payload) => {
    try {
      const response = await axiosNisystAdmin.post(FORGOT_PASSWORD, payload);
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const resetPasswordAdmin = createAsyncThunk(
  `authentication/resetPasswordAdmin`,
  async (payload) => {
    try {
      const response = await axiosNisystAdmin.post(RESET_PASSWORD, payload);
      return response;
    } catch (err) {
      return err;
    }
  }
);

const loginSlice = createSlice({
  name: "login",
  initialState: loginState,
  reducers: {
    setPageLoader: (state, action) => {
      state.pageLoader = action.payload;
    },
  },
  extraReducers: (builder) => {
    //login
    builder.addCase(getLogin.pending, (state) => {
      state.loading = true;
      state.isAuth = false;
    });
    builder.addCase(getLogin.fulfilled, (state) => {
      state.loading = false;
      state.isAuth = false;
    });
    builder.addCase(getLogin.rejected, (state) => {
      state.loading = false;
      state.isAuth = false;
    });
    //Logout
    builder.addCase(logout.pending, (state) => {
      state.loading = true;
      state.isAuth = false;
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.isAuth = false;
      state.token = "";
    });
    builder.addCase(logout.rejected, (state) => {
      state.loading = false;
      state.isAuth = false;
    });
    //get Verify Auth
    builder.addCase(getVerifyAuth.pending, (state) => {
      state.loading = true;
      state.isAuth = false;
    });
    builder.addCase(getVerifyAuth.fulfilled, (state, action) => {
      state.loading = false;
      state.isAuth = true;
      state.token = action.payload.data.token;
    });
    builder.addCase(getVerifyAuth.rejected, (state) => {
      state.loading = false;
      state.isAuth = false;
    });
    //get Resend Verify Auth
    builder.addCase(getResendVerifyAuth.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getResendVerifyAuth.fulfilled, (state) => {
      state.isAuth = false;
    });
    builder.addCase(getResendVerifyAuth.rejected, (state) => {
      state.loading = false;
    });
  },
});
export const { setPageLoader } = loginSlice.actions;
export default loginSlice.reducer;
