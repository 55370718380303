import { axiosNisystAdmin } from "@/services/api";
import {
  ARCHIVE_SERVICE_ASYNC,
  DELETE_SERVICE,
  GET_ALL_SERVICE_LIST,
  GET_SERVICE_BY_ID,
  UPSERT_SERVICE,
} from "@/services/url";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const serviceState = {
  services: null,
  loading: false,
  error: null,
  totalCount: 0,
  status: {
    label: "Active",
    value: 1,
  },
  serviceDetails: null,
  serviceLoading: false,
  filters: {
    searchQuery: "",
    pagination: {
      pageIndex: 0,
      pageSize: 10,
    },
    sorting: [],
  },
};

// Get service list api call
export const getServices = createAsyncThunk(
  `serviceThunk/getServices`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.post(
        "Task/" + GET_ALL_SERVICE_LIST,
        payload
      );
      return response.data;
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

// Delete service api call
export const deleteService = createAsyncThunk(
  `serviceThunk/deleteService`,
  async (payload, thunkAPI) => {
    const { callback = () => {}, handleDeleteService = () => {} } = payload;
    try {
      const response = await axiosNisystAdmin.post(
        `Task/${DELETE_SERVICE}`,
        payload
      );
      if (!!response?.data) {
        callback(response?.data);
        handleDeleteService(response?.data);
        return response.data;
      }
    } catch (err) {
      callback(err?.response?.data);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

// Get service details by Id api call
export const getServiceById = createAsyncThunk(
  `serviceThunk/getServiceById`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.post(
        "Task/" + GET_SERVICE_BY_ID,
        payload
      );
      return response.data;
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

// Active/Deactive service api call
export const activeDeActiveService = createAsyncThunk(
  `serviceThunk/activeDeActiveService`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.post(
        "Task/" + ARCHIVE_SERVICE_ASYNC,
        payload
      );
      return response.data;
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

// Add/Edit service api call
export const upsertService = createAsyncThunk(
  `serviceThunk/upsertService`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.post(
        "Task/" + UPSERT_SERVICE,
        payload
      );
      return response;
    } catch (err) {
      toast.error(err?.response?.data?.message || err?.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

const serviceSlice = createSlice({
  name: "service",
  initialState: serviceState,
  reducers: {
    setServiceLoading: (state, action) => {
      state.loading = action.payload;
    },
    setServiceSearchQuery: (state, action) => {
      state.filters.searchQuery = action.payload;
    },
    setServicePagination: (state, action) => {
      state.filters.pagination = action.payload;
    },
    setServiceSorting: (state, action) => {
      state.filters.sorting = action.payload;
    },
    setServiceStatus: (state, action) => {
      state.status = action.payload;
    },
    resetServiceState: () => {
      return {
        ...serviceState,
      };
    },
  },

  extraReducers: (builder) => {
    /*  Get All Services */
    builder.addCase(getServices.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getServices.fulfilled, (state, action) => {
      state.loading = false;
      state.totalCount = action.payload?.data?.totalRecords;
      state.services = action.payload?.data?.records;
    });
    builder.addCase(getServices.rejected, (state) => {
      state.loading = false;
      state.services = null;
    });

    /* Get Service By Id */
    builder.addCase(getServiceById.pending, (state) => {
      state.serviceLoading = true;
      state.serviceDetails = null;
    });
    builder.addCase(getServiceById.fulfilled, (state, action) => {
      state.serviceLoading = false;
      state.serviceDetails = action.payload?.data;
    });
    builder.addCase(getServiceById.rejected, (state) => {
      state.serviceLoading = false;
      state.serviceDetails = null;
    });

    /* Reset entire state */
    builder.addCase(resetServiceState, () => {
      return {
        ...serviceState,
      };
    });
  },
});

export default serviceSlice.reducer;

export const {
  setServiceLoading,
  setServiceSearchQuery,
  setServicePagination,
  setServiceSorting,
  setServiceStatus,
  resetServiceState,
} = serviceSlice.actions;
