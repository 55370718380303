import axios from "axios";
import conf from "@/conf/conf";
import Cookies, { cookieKeys } from "./cookies";

const API_URL = `${conf.APIUrl}/api`;
class Axios {
  constructor(baseURL) {
    this.axios = axios.create({
      baseURL,
    });
    this.axios.interceptors.request.use(this._requestMiddleware);
    this.axios.interceptors.response.use(
      this._responseMiddleware,
      this._responseErr
    );
    this.isRefreshing = false;
    this.failedQueue = [];
  }

  _requestMiddleware = (req) => {
    // Send Bearer token on every request
    const token = Cookies.get(cookieKeys?.TOKEN);
    const nisystRefreshToken = Cookies.get(cookieKeys?.REFRESH_TOKEN);
    if (token)
      req.headers.authorization = token.startsWith("Token")
        ? token
        : "Bearer " + token;
    if (!!nisystRefreshToken) {
      req.headers.refreshToken = nisystRefreshToken;
    }
    return req;
  };

  _responseMiddleware = (response) => {
    //  Do something on every success full response.
    if (response?.data?.token) {
      Cookies.set(cookieKeys?.TOKEN, response.data?.token);
      if (response?.data?.data?.refreshToken) {
        Cookies.set(
          cookieKeys?.REFRESH_TOKEN,
          response?.data?.data?.refreshToken
        );
      }
    }
    return response;
  };

  _responseErr = async (error) => {
    // If the error response is a 401 Unauthorized, attempt to refresh the token
    if (error?.response?.status === 401) {
      const refreshToken = Cookies.get(cookieKeys?.REFRESH_TOKEN);
      const accessToken = Cookies.get(cookieKeys?.TOKEN);
      if (refreshToken) {
        if (this.isRefreshing) {
          // Queue requests until the refresh token process is complete
          return new Promise((resolve, reject) => {
            this.failedQueue.push({ resolve, reject });
          })
            .then((token) => {
              error.config.headers["Authorization"] = `Bearer ${token}`;
              return axios(error.config);
            })
            .catch((err) => Promise.reject(err));
        }

        this.isRefreshing = true;
        try {
          // Call the refresh endpoint to get a new access token
          const response = await axios.post(
            `${API_URL}/Authentication/RefreshToken`,
            { accessToken: accessToken, refreshToken: refreshToken }
          );
          if (response?.data?.token) {
            const newAccessToken = response?.data?.token;
            // Save the new access token in cookies
            Cookies.set(cookieKeys?.TOKEN, newAccessToken);
            // Retry all failed requests
            this.failedQueue.forEach((prom) => prom.resolve(newAccessToken));
            this.failedQueue = [];
            // Retry the original request
            error.config.headers["Authorization"] = `Bearer ${newAccessToken}`;
            return axios(error.config);
          }
        } catch (err) {
          // If the refresh token fails (invalid or expired), clear cookies and reload the page
          this.failedQueue.forEach((prom) => prom.reject(err));
          this.failedQueue = [];
          Cookies.clear();
          window.location.reload();
          return await Promise.reject(err);
        } finally {
          this.isRefreshing = false;
        }
      } else {
        // If no refresh token is available, clear cookies and reload the page
        Cookies.clear();
        window.location.reload();
        return await Promise.reject(error);
      }
    }
    return await Promise.reject(error);
  };
}

const axiosNisystAdmin = new Axios(API_URL).axios;
export { axiosNisystAdmin };
