import { axiosNisystAdmin } from "@/services/api";
import {
  CHECK_PRODUCT_SERIAL_NUMBER_EXISTS,
  DELETE_GOOD_RECEIPT_ITEM_ID,
  DELETE_RECEIPT,
  GET_GOODS_RECEIPT_BY_ID,
  GET_GOOD_RECEIPT_ITEMS_LIST,
  GET_GOOD_RECEIPT_LIST,
  GET_MASTER_DATA_GOOD_RECEIPT,
  UPSERT_GOODSRECEIPT,
  UPSERT_GOODSRECEIPT_ITEM,
  UPSERT_SERIAL_NUMBER,
} from "@/services/url";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const goodReceiptState = {
  masterData: null,
  masterLoad: false,
  loading: true,
  listGoodReceipt: [],
  listGoodReceiptItems: [],
  recieptData: [],
  singleReciept: null,
  filters: {
    searchQuery: "",
    status: 1,
    pagination: {
      pageIndex: 0,
      pageSize: 10,
      totalRecords: 0,
    },
    listPagination: {
      pageIndex: 0,
      pageSize: 10,
      totalRecords: 0,
    },
    sorting: [],
  },
  goodReceiptItemFilter: {
    pagination: {
      pageIndex: 0,
      pageSize: 10,
      totalRecords: 0,
    },
    sorting: [],
  },
  upsertGoodReceiptState: {
    id: "",
    message: "",
    loading: false,
  },
};

export const getGoodReceiptMasterData = createAsyncThunk(
  `getGoodReceiptMasterData`,
  async () => {
    try {
      const response = await axiosNisystAdmin.get(GET_MASTER_DATA_GOOD_RECEIPT);
      return response?.data;
    } catch (err) {
      return err
    }
  }
);

export const fetchListGoodReceipt = createAsyncThunk(
  `fetchListGoodReceipt`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.post(
        GET_GOOD_RECEIPT_LIST,
        payload
      );
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

export const fetchListGoodReceiptItems = createAsyncThunk(
  `fetchListGoodReceiptItems`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.post(
        GET_GOOD_RECEIPT_ITEMS_LIST,
        payload
      );
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

export const deleteGoodReceiptItem = createAsyncThunk(
  `deleteGoodReceiptItem`,
  async (payload, thunkAPI) => {
    const { callback = () => { } } = payload;
    try {
      const response = await axiosNisystAdmin.post(
        DELETE_GOOD_RECEIPT_ITEM_ID,
        payload
      );
      callback(response?.data);
      return response.data;
    } catch (err) {
      callback(err?.response?.data);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

export const upsertGoodReceipt = createAsyncThunk(
  `upsertGoodReceipt`,
  async (payload) => {
    try {
      const response = await axiosNisystAdmin.post(
        UPSERT_GOODSRECEIPT,
        payload
      );
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const checkProductSerialNumberExists = createAsyncThunk(
  `checkProductSerialNumberExists`,
  async (payload) => {
    try {
      const response = await axiosNisystAdmin.post(
        CHECK_PRODUCT_SERIAL_NUMBER_EXISTS,
        payload
      );
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const deleteReceipt = createAsyncThunk(
  `goodReceipt/deleteReceipt`,
  async (payload, thunkAPI) => {
    const { callback = () => { }, handleDeleteReceipt = () => { } } = payload;
    try {
      const response = await axiosNisystAdmin.post(
        `${DELETE_RECEIPT}`,
        payload
      );
      if (!!response?.data) {
        callback(response?.data);
        handleDeleteReceipt(response?.data);
        return response.data;
      }
    } catch (err) {
      callback(err?.response?.data);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

export const getGoodReceiptById = createAsyncThunk(
  `goodReceipt/getGoodReceiptById`,
  async (payload) => {
    try {
      const response = await axiosNisystAdmin.post(
        `${GET_GOODS_RECEIPT_BY_ID}`,
        payload
      );
      return response
    } catch (err) {
      return err
    }
  }
);

export const upsertGoodReceiptItem = createAsyncThunk(
  `goodReceipt/upsertGoodReceiptItem`,
  async (payload) => {
    try {
      const response = await axiosNisystAdmin.post(
        `${UPSERT_GOODSRECEIPT_ITEM}`,
        payload
      );
      return response
    } catch (err) {
      return err
    }
  }
);

export const upsertSerialNumber = createAsyncThunk(
  `goodReceipt/upsertSerialNumber`,
  async (payload) => {
    try {
      const response = await axiosNisystAdmin.post(
        `${UPSERT_SERIAL_NUMBER}`,
        payload
      );
      return response
    } catch (err) {
      return err
    }
  }
);

const GoodReceiptSlice = createSlice({
  name: "goodReceipt",
  initialState: goodReceiptState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setUsersSearchQuery: (state, action) => {
      state.filters.searchQuery = action.payload;
    },
    setUsersPagination: (state, action) => {
      state.filters.pagination = action.payload;
    },
    setGoodReceiptItemPagination: (state, action) => {
      state.goodReceiptItemFilter.pagination = action.payload;
    },
    setListPagination: (state, action) => {
      state.filters.listPagination = action.payload;
    },
    setUsersSorting: (state, action) => {
      state.filters.sorting = action.payload;
    },
    setGoodReceiptItemSorting: (state, action) => {
      state.goodReceiptItemFilter.sorting = action.payload;
    },
    setGoodReceiptItems: (state, action) => {
      state.recieptData = action.payload;
    },
    setSingleRecieptadata: (state, action) => {
      state.singleReciept = action.payload;
    },
    setGoodReceiptStatus: (state, action) => {
      state.filters.status = action.payload;
    },
    resetGoodReceipt: (state) => {
      state.upsertGoodReceiptState.id = "";
      state.upsertGoodReceiptState.message = "";
      state.upsertGoodReceiptState.loading = false;
      (state.listGoodReceiptItems = []),
        (state.filters = {
          searchQuery: "",
          status: 1,
          pagination: {
            pageIndex: 0,
            pageSize: 10,
            totalRecords: 0,
          },
          sorting: [],
        });
    },

    resetGoodReceiptState: () => {
      return {
        ...goodReceiptState,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchListGoodReceipt.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchListGoodReceipt.fulfilled, (state, action) => {
        state.loading = false;
        state.listGoodReceipt = action.payload?.data?.records || [];
        state.totalRecords = action.payload?.data?.totalRecords || 0;
      })
      .addCase(fetchListGoodReceipt.rejected, (state) => {
        state.loading = false;
        state.listGoodReceipt = [];
      })
      .addCase(upsertGoodReceipt.pending, (state) => {
        state.upsertGoodReceiptState.loading = true;
      })
      .addCase(upsertGoodReceipt.fulfilled, (state, action) => {
        state.upsertGoodReceiptState.loading = false;
        state.upsertGoodReceiptState.id =
          action.payload?.data?.goodReceiptId || "";
        state.upsertGoodReceiptState.message = action.payload?.message || "";
        // Optionally, update state to reflect the upserted good receipt
      })
      .addCase(upsertGoodReceipt.rejected, (state, action) => {
        state.upsertGoodReceiptState.loading = false;
        state.upsertGoodReceiptState.message = action.payload?.message || "";
      })
      .addCase(fetchListGoodReceiptItems.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchListGoodReceiptItems.fulfilled, (state, action) => {
        state.loading = false;
        state.listGoodReceiptItems = action.payload?.data?.records || [];
        state.totalRecords = action.payload?.data?.totalRecords || 0;
      })
      .addCase(fetchListGoodReceiptItems.rejected, (state) => {
        state.loading = false;
        state.listGoodReceipt = [];
      })
      //getting master data
      .addCase(getGoodReceiptMasterData.pending, (state) => {
        state.masterLoad = true;
      })
      .addCase(getGoodReceiptMasterData.fulfilled, (state, action) => {
        state.masterLoad = false;
        state.masterData = action.payload?.data || [];
      })
      .addCase(getGoodReceiptMasterData.rejected, (state) => {
        state.masterLoad = false;
      });

    /* Reset entire state */
    builder.addCase(resetGoodReceiptState, () => {
      return {
        ...goodReceiptState,
      };
    });
  },
});

export const {
  setLoading,
  setUsersSearchQuery,
  setUsersPagination,
  setGoodReceiptItemPagination,
  setGoodReceiptItemSorting,
  setUsersSorting,
  resetGoodReceipt,
  setGoodReceiptItems,
  setSingleRecieptadata,
  resetGoodReceiptState,
  setListPagination,
  setGoodReceiptStatus
} = GoodReceiptSlice.actions;

export default GoodReceiptSlice.reducer;
