import { axiosNisystAdmin } from "@/services/api";
import {
  GET_TOP_ENGINEERS,
  GET_COMPLETE_JOB,
  GET_JOB_OVERVIEW_DATA,
  GET_PRODUCT_DETAILS_CARD,
  UPCOMING_JOBS,
} from "@/services/url";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const dashboardState = {
  loading: false,
  companyByEngineer: {
    filter: null,
  },
  dashboardCards: null,
  upcomingJobs: null,
  upcomingLoading: null,
  topEngineers: null,
  completedJobs: null,
  jobOverviewData: null,
  jobCount: 0,
  jobFilters: {
    pagination: {
      pageIndex: 0,
      pageSize: 10,
    },
    sorting: [],
  },
};

export const getProductDetailCards = createAsyncThunk(
  `dashboardThunk/getProductDetailCards`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.get(GET_PRODUCT_DETAILS_CARD);
      return response;
    } catch (err) {
      toast.error(err?.response?.data?.message || err?.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

export const getUpcomingJobs = createAsyncThunk(
  `dashboardThunk/getUpcomingJobs`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.post(UPCOMING_JOBS, payload);
      return response;
    } catch (err) {
      toast.error(err?.response?.data?.message || err?.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

// Top Engineers
export const GetTopEngineers = createAsyncThunk(
  `dashboardThunk/GetTopEngineers`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.post(GET_TOP_ENGINEERS, {
        ...payload,
      });
      return response;
    } catch (err) {
      toast.error(err?.response?.data?.message || err?.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

export const getCompletedJobs = createAsyncThunk(
  `dashboardThunk/getCompletedJobs`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.get(GET_COMPLETE_JOB);
      return response;
    } catch (err) {
      toast.error(err?.response?.data?.message || err?.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

export const getJobOverviewData = createAsyncThunk(
  `dashboardThunk/getJobOverviewData`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.post(
        GET_JOB_OVERVIEW_DATA,
        payload
      );
      return response;
    } catch (err) {
      toast.error(err?.response?.data?.message || err?.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: dashboardState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setJobOverviewPagination: (state, action) => {
      state.jobFilters.pagination = action.payload;
    },
    setJobOverviewSorting: (state, action) => {
      state.jobFilters.sorting = action.payload;
    },
    resetDashboardState: () => {
      return {
        ...dashboardState,
      };
    },
  },

  extraReducers: (builder) => {
    /*  Get Product Details Card */
    builder.addCase(getProductDetailCards.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getProductDetailCards.fulfilled, (state, action) => {
      state.loading = false;
      state.dashboardCards = action.payload?.data?.data;
    });
    builder.addCase(getProductDetailCards.rejected, (state) => {
      state.loading = false;
      state.dashboardCards = null;
    });

    /*  Get Upcoming Jobs */
    builder.addCase(getUpcomingJobs.pending, (state) => {
      state.upcomingLoading = true;
    });
    builder.addCase(getUpcomingJobs.fulfilled, (state, action) => {
      state.upcomingLoading = false;
      state.upcomingJobs = action.payload?.data?.data;
    });
    builder.addCase(getUpcomingJobs.rejected, (state) => {
      state.upcomingLoading = false;
      state.upcomingJobs = null;
    });

    // Top engineers

    builder.addCase(GetTopEngineers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(GetTopEngineers.fulfilled, (state, action) => {
      state.loading = false;
      state.topEngineers = action.payload?.data?.data;
    });
    builder.addCase(GetTopEngineers.rejected, (state) => {
      state.loading = false;
      state.topEngineers = null;
    });

    /*  Get Completed Jobs */
    builder.addCase(getCompletedJobs.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCompletedJobs.fulfilled, (state, action) => {
      state.loading = false;
      state.completedJobs = action.payload?.data?.data;
    });
    builder.addCase(getCompletedJobs.rejected, (state) => {
      state.loading = false;
      state.completedJobs = null;
    });

    /*  Get Job Overview Data */
    builder.addCase(getJobOverviewData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getJobOverviewData.fulfilled, (state, action) => {
      state.loading = false;
      state.jobOverviewData = action.payload?.data?.data?.records;
      state.jobCount = action.payload?.data?.data?.totalRecords;
    });
    builder.addCase(getJobOverviewData.rejected, (state) => {
      state.loading = false;
      state.jobOverviewData = null;
    });

    /* Reset entire state */
    builder.addCase(resetDashboardState, () => {
      return {
        ...dashboardState,
      };
    });
  },
});

export const {
  setLoading,
  resetDashboardState,
  setJobOverviewPagination,
  setJobOverviewSorting,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
