import { axiosNisystAdmin } from "@/services/api";
import { BLOCK_ADMIN, DELETE_ADMIN, GET_ADMIN_BY_ID, GET_ADMIN_LIST, GET_ALL_COMPANIES, GET_MASTER_DATA_ADMIN, GET_MASTER_PERMISSION_PROFILE, UPSERT_ADMIN } from "@/services/url";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const adminState = {
  loading: true,
  listAdmin: [],
  filters: {
    searchQuery: "",
    status: 1,
    pagination: {
      pageIndex: 0,
      pageSize: 10,
      totalRecords: 0,
    },
    sorting: [],
    selectedComapnyFilter: "",
  },
};

export const fetchListAdmin = createAsyncThunk(
  `fetchListAdmin`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.post(GET_ADMIN_LIST, payload);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

export const deleteAdmin = createAsyncThunk(
  `deleteAdmin`,
  async (payload) => {
    try {
      const response = await axiosNisystAdmin.post(DELETE_ADMIN, payload);
      return response;  
    } catch (err) {
      return err;
    }
  }
);

export const blockAdmin = createAsyncThunk(
  `blockAdmin`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.post(BLOCK_ADMIN, payload);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

export const upsertAdmin = createAsyncThunk(
  `upsertAdmin`,
  async (payload) => {
    try {
      const response = await axiosNisystAdmin.post(UPSERT_ADMIN, payload);
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const getCompanyList = createAsyncThunk(
  `getAllCompanies`,
  async () => {
    try {
      const response = await axiosNisystAdmin.get(GET_ALL_COMPANIES);
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const getMasterDataAdmin = createAsyncThunk(
  `getMasterDataAdmin`,
  async () => {
    try {
      const response = await axiosNisystAdmin.get(GET_MASTER_DATA_ADMIN);
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const getPermissionMasterData = createAsyncThunk(
  `getPermissionMasterData`,
  async () => {
    try {
      const response = await axiosNisystAdmin.get(GET_MASTER_PERMISSION_PROFILE);
      return response;
    } catch (err) {
      return err;
    }
  }
);


export const getAdminById = createAsyncThunk(
  `getMasterDataAdmin`,
  async (payload) => {
    try {
      const response = await axiosNisystAdmin.post(GET_ADMIN_BY_ID, payload);
      return response;
    } catch (err) {
      return err;
    }
  }
);

const adminSlice = createSlice({
  name: "admin",
  initialState: adminState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setUsersSearchQuery: (state, action) => {
      state.filters.searchQuery = action.payload;
    },
    setUsersPagination: (state, action) => {
      state.filters.pagination = action.payload;
    },
    setUsersSorting: (state, action) => {
      state.filters.sorting = action.payload;
    },
    setAdminCompanyFilter: (state, action) => {
      state.filters.selectedComapnyFilter = action.payload;
    },
    setAdminFilter: (state, action) => {
      state.filters.status = action.payload;
    },
    resetAdminState: () => {
      return {
        ...adminState,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchListAdmin.pending, () => {
      // state.loading = true;
    })
    builder.addCase(fetchListAdmin.fulfilled, (state, action) => {
      state.loading = false;
      state.listAdmin = action.payload?.data?.data?.records || [];
      state.totalRecords = action.payload?.data?.data?.totalRecords || 0;
    })
    builder.addCase(fetchListAdmin.rejected, (state) => {
      state.loading = false;
      state.listAdmin = [];
    })
    builder.addCase(deleteAdmin.pending, (state) => {
      state.loading = true;
    })
    builder.addCase(deleteAdmin.fulfilled, (state) => {
      state.loading = false;
      // Optionally, update state to remove the deleted admin
    })
    builder.addCase(deleteAdmin.rejected, (state) => {
      state.loading = false;
    })
      .addCase(blockAdmin.pending, (state) => {
        state.loading = true;
      })
    builder.addCase(blockAdmin.fulfilled, (state) => {
      state.loading = false;
      // Optionally, update state to mark the admin as blocked
    })
    builder.addCase(blockAdmin.rejected, (state) => {
      state.loading = false;
    });

    /* Reset entire state */
    builder.addCase(resetAdminState, () => {
      return {
        ...adminState,
      };
    });
  },
});

export const {
  setLoading,
  setUsersSearchQuery,
  setUsersPagination,
  setUsersSorting,
  setAdminCompanyFilter,
  setAdminFilter,
  resetAdminState,
} = adminSlice.actions;

export default adminSlice.reducer;
