import { toast } from "react-toastify";
import { axiosNisystAdmin } from "@/services/api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { somethingWentWrong } from "@/constants/SchemaValidation";
import {
  CANCEL_JOB,
  FETCH_ALL_JOBS_MASTER_DATA,
  GET_ALL_NOTES,
  FETCH_DELIVERY_NOTE_BY_JOBS,
  GET_JOBS_BY_ID,
  GET_SUPPORT_DETAILS,
  LIST_ALL_JOBS,
  UPSERT_JOB,
  UPSERT_JOB_CARD_NOTE,
  DELETE_JOB_CARD_NOTE,
  UPSERT_SUPPORT_DETAILS,
  GET_COMPLETE_SHOP_DETAILS,
  FETCH_JOB_TASK_PROGRESS,
  RESCHEDULE_JOB,
  CLOSE_JOB_SUPPORT,
  DELETE_SUPPORT_DETAILS,
  GET_TASK_DETAILS_BY_JOB,
  GET_TASK_DETAILS_TEMPLATE,
  GET_POS_EVENT_LOGS_ASYNC,
  IMPORT_JOB,
  GET_ACTIVITY_LOGS_BY_JOB_ID,
  EXPORT_JOBS_CARD_PDF,
  GET_CUSTOMERS_FEEDBACK,
  FETCH_ACTIVITY_AND_EVENT_LOGS,
} from "@/services/url";

const jobState = {
  jobsLists: [],
  jobsData: {},
  loading: false,
  jobIdLoader: false,
  notesList: null,
  notesLoading: false,
  supportDetails: null,
  supportLoading: false,
  filters: {
    searchQuery: "",
    sorting: [],
    status: { label: "Outstanding", value: 0 },
    pagination: {
      pageIndex: 0,
      pageSize: 10,
      totalRecords: 0,
    },
    byProject: "",
    byDate: "",
  },
  masterData: [],
  jobViewData: {},
  jobsDelivery: {
    jobsDeliveryList: [],
    jobsDeliveryData: {},
    loading: true,
    filters: {
      sorting: [],
      pagination: {
        pageIndex: 0,
        pageSize: 10,
      },
      search: "",
    },
  },
  completeShopData: [],
  jobTaskProgressData: [],
  rescheduleData: [],
  jobTaskDetailAdditional: [],
  jobTaskDetailTemplate: [],
  jobSystemTaskDetailTemplate: [],
  jobImportData: "",
  activityList: [],
  activityListLoading: false,
  activityHasMore: false,
  activitLogSearch: "",
  jobsCardExportLoading: false,
  customersFeedback: {},
  //Activity and event logs
  activityAndEventLogs: [],
  activityAndEventLogsLoading: false,
  activityAndEventLogsSearch: "",
  activityAndEventLogsFilter: {
    status: { value: 0, label: "All" },
  },
};

//fetchAllJobs
export const fetchAllJobs = createAsyncThunk(
  `jobs/fetchAllJobs`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.post(LIST_ALL_JOBS, payload);
      return response;
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

//fetchAllMasterDataForJob
export const fetchAllMasterDataForJob = createAsyncThunk(
  `jobs/fetchAllMasterDataForJob`,
  async (thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.get(FETCH_ALL_JOBS_MASTER_DATA);
      return response;
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

//getJobsById
export const getJobsById = createAsyncThunk(
  `jobs/getJobsById`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.post(GET_JOBS_BY_ID, payload);
      return response;
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

// Fetch delivery notes
export const fetchDeliveryNoteByJob = createAsyncThunk(
  `jobs/fetchDeliveryNoteByJob`,
  async (payload) => {
    try {
      const response = await axiosNisystAdmin.post(
        FETCH_DELIVERY_NOTE_BY_JOBS,
        payload
      );
      return response;
    } catch (err) {
      return err;
    }
  }
);
//Upsert Job
export const upsertJob = createAsyncThunk(
  `JobThunk/upsertJob`,
  async (payload, thunkAPI) => {
    try {
      const { callback } = payload;
      const response = await axiosNisystAdmin.post(UPSERT_JOB, payload);
      if (!!response?.data) {
        callback(response);
        toast.success(response?.data?.message);
        return response.data;
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || somethingWentWrong);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

//Delete Job
export const cancelJob = createAsyncThunk(
  `jobs/cancelJob`,
  async (payload, thunkAPI) => {
    const { callback = () => {} } = payload;
    try {
      const response = await axiosNisystAdmin.post(CANCEL_JOB, payload);
      if (!!response?.data) {
        callback(response);
        return response;
      }
    } catch (err) {
      callback(err);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

//fetchAllNotes
export const fetchAllNotes = createAsyncThunk(
  `jobs/fetchAllNotes`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.post(GET_ALL_NOTES, payload);
      return response;
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

//Upsert Job note
export const upsertJobCardNote = createAsyncThunk(
  `JobThunk/upsertJobCardNote`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.post(
        UPSERT_JOB_CARD_NOTE,
        payload
      );
      if (!!response?.data) {
        toast.success(response?.data?.message);
        return response.data;
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || somethingWentWrong);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

//Delete Job note
export const deleteJobCardNote = createAsyncThunk(
  `JobThunk/deleteJobCardNote`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.post(
        DELETE_JOB_CARD_NOTE,
        payload
      );
      if (!!response?.data) {
        return response.data;
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || somethingWentWrong);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

//fetchSupportDetails
export const fetchSupportDetails = createAsyncThunk(
  `jobs/fetchSupportDetails`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.post(
        GET_SUPPORT_DETAILS,
        payload
      );
      return response;
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

//Upsert support details
export const upsertSupportDetails = createAsyncThunk(
  `JobThunk/upsertSupportDetails`,
  async (payload) => {
    try {
      const response = await axiosNisystAdmin.post(
        UPSERT_SUPPORT_DETAILS,
        payload
      );
      if (!!response?.data) {
        return response.data;
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || somethingWentWrong);
    }
  }
);

//Close support details
export const closeSupportDetails = createAsyncThunk(
  `JobThunk/closeSupportDetails`,
  async (payload) => {
    try {
      const response = await axiosNisystAdmin.post(CLOSE_JOB_SUPPORT, payload);
      if (!!response?.data) {
        toast.success(response?.data?.message);
        return response.data;
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || somethingWentWrong);
    }
  }
);

//Delete support details
export const deleteSupportDetails = createAsyncThunk(
  `JobThunk/deleteSupportDetails`,
  async (payload) => {
    try {
      const response = await axiosNisystAdmin.post(
        DELETE_SUPPORT_DETAILS,
        payload
      );
      if (!!response?.data) {
        toast.success(response?.data?.message);
        return response.data;
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || somethingWentWrong);
    }
  }
);

//fetchCompleteShopDetails
export const fetchCompleteShopDetails = createAsyncThunk(
  `jobs/fetchCompleteShopDetails`,
  async (id, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.post(
        GET_COMPLETE_SHOP_DETAILS,
        id
      );
      return response;
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

export const fetchAllJobTaskProgress = createAsyncThunk(
  `jobs/fetchAllJobTaskProgress`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.post(
        FETCH_JOB_TASK_PROGRESS,
        payload
      );
      return response;
    } catch (err) {
      toast.error(err.response.data.message || err.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

export const resecheduleJob = createAsyncThunk(
  `jobs/resecheduleJob`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.post(RESCHEDULE_JOB, payload);
      return response;
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

// Task Details By Job
export const fetchTaskDetailByJob = createAsyncThunk(
  `jobs/fetchTaskDetailByJob`,
  async (payload) => {
    try {
      const response = await axiosNisystAdmin.post(
        GET_TASK_DETAILS_BY_JOB,
        payload
      );
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const fetchTaskDetailTemplate = createAsyncThunk(
  `jobs/fetchTaskDetailTemplate`,
  async (payload) => {
    try {
      const response = await axiosNisystAdmin.post(
        GET_TASK_DETAILS_TEMPLATE,
        payload
      );
      return response;
    } catch (err) {
      return err;
    }
  }
);
// System log
export const fetchSystemTaskDetailTemplate = createAsyncThunk(
  `jobs/fetchSystemTaskDetailTemplate`,
  async (payload) => {
    try {
      const response = await axiosNisystAdmin.post(
        GET_POS_EVENT_LOGS_ASYNC,
        payload
      );
      return response;
    } catch (err) {
      return err;
    }
  }
);
//import jobs
export const importJob = createAsyncThunk(
  `customerThunk/importJob`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.post(IMPORT_JOB, payload);
      if (response?.data) {
        toast.success(response?.data?.message);
        return response.data;
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

//activity listing
export const fetchActivityListing = createAsyncThunk(
  `jobs/fetchActivityListing`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.post(
        GET_ACTIVITY_LOGS_BY_JOB_ID,
        payload
      );
      return response;
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

//export job card PDF
export const exportJobCardPDF = createAsyncThunk(
  `jobs/exportJobCardPDF`,
  async (payload, thunkAPI) => {
    const { callback = () => {} } = payload;
    try {
      const response = await axiosNisystAdmin.post(
        EXPORT_JOBS_CARD_PDF,
        payload
      );
      if (!!response?.data) {
        callback(response?.data);
        return response.data;
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

//get Customers Feedback
export const getCustomersFeedback = createAsyncThunk(
  `jobs/getCustomersFeedback`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.post(
        GET_CUSTOMERS_FEEDBACK,
        payload
      );
      return response;
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

//Activity And Event Logs
export const fetchActivityAndEventLogs = createAsyncThunk(
  `jobs/fetchActivityAndEventLogs`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.post(
        FETCH_ACTIVITY_AND_EVENT_LOGS,
        payload
      );
      return response;
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

const jobSlice = createSlice({
  name: "jobs",
  initialState: jobState,
  reducers: {
    setJobsLoading: (state, action) => {
      state.loading = action.payload;
    },
    setJobsSearchQuery: (state, action) => {
      state.filters.searchQuery = action.payload;
    },
    setJobsSorting: (state, action) => {
      state.filters.sorting = action.payload;
    },
    setJobsPagination: (state, action) => {
      state.filters.pagination = action.payload;
    },
    setJobsStatusFilter: (state, action) => {
      state.filters.status = action.payload;
    },
    setJobsProjectFilter: (state, action) => {
      state.filters.byProject = action.payload;
    },
    setJobsDateFilter: (state, action) => {
      state.filters.byDate = action.payload;
    },

    // JOB DELIVERY NOTES
    setJobDeliveryLoading: (state, action) => {
      state.jobsDelivery.loading = action.payload;
    },
    setJobDeliverySearchQuery: (state, action) => {
      state.jobsDelivery.filters.search = action.payload;
    },
    setActivityLogsSearchQuery: (state, action) => {
      state.activitLogSearch = action.payload;
    },
    setActivityAndEventLogsSearchQuery: (state, action) => {
      state.activityAndEventLogsSearch = action.payload;
    },
    setActivityAndEventLogsFilter: (state, action) => {
      state.activityAndEventLogsFilter.status = action.payload;
    },
    resetActivityAndEventLogsFilter: (state) => {
      state.activityAndEventLogsFilter.status =
        jobState.activityAndEventLogsFilter.status; // Reset to default
    },
    resetJobState: () => {
      return {
        ...jobState,
      };
    },
  },
  extraReducers: (builder) => {
    //fetchAllJobs
    builder.addCase(fetchAllJobs.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllJobs.fulfilled, (state, action) => {
      state.loading = false;
      state.jobsLists = action.payload?.data?.data?.records;
      state.jobsData = action.payload.data;
      state.totalRecords = action.payload?.data?.data?.totalRecords || 0;
    });
    builder.addCase(fetchAllJobs.rejected, (state) => {
      state.loading = false;
      state.jobsLists = [];
    });
    //fetchAllJobsMasterData
    builder.addCase(fetchAllMasterDataForJob.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllMasterDataForJob.fulfilled, (state, action) => {
      state.loading = true;
      state.masterData = action.payload.data.data;
    });
    builder.addCase(fetchAllMasterDataForJob.rejected, (state) => {
      state.loading = false;
      state.masterData = [];
    });
    //getJobsById
    builder.addCase(getJobsById.pending, (state) => {
      state.jobIdLoader = true;
    });
    builder.addCase(getJobsById.fulfilled, (state, action) => {
      state.jobIdLoader = false;
      state.jobViewData = action.payload.data;
    });
    builder.addCase(getJobsById.rejected, (state) => {
      state.jobIdLoader = false;
      state.jobViewData = [];
    });
    //
    builder.addCase(fetchDeliveryNoteByJob.pending, () => {});
    builder.addCase(fetchDeliveryNoteByJob.fulfilled, (state, action) => {
      state.jobsDelivery.loading = false;
      state.jobsDelivery.jobsDeliveryData = action?.payload?.data;
      state.jobsDelivery.jobsDeliveryList = action?.payload?.data?.data;
    });
    builder.addCase(fetchDeliveryNoteByJob.rejected, (state) => {
      state.jobsDelivery.loading = false;
      state.jobsDelivery.jobsDeliveryList = [];
    });
    // Upsert job
    builder.addCase(upsertJob.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(upsertJob.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(upsertJob.rejected, (state) => {
      state.loading = false;
    });

    // Fetch all notes
    builder.addCase(fetchAllNotes.pending, (state) => {
      state.notesLoading = true;
    });
    builder.addCase(fetchAllNotes.fulfilled, (state, action) => {
      state.notesList = action.payload?.data?.data?.records;
      state.notesLoading = false;
    });
    builder.addCase(fetchAllNotes.rejected, (state) => {
      state.notesLoading = false;
    });

    // Fetch support details
    builder.addCase(fetchSupportDetails.pending, (state) => {
      state.supportLoading = true;
    });
    builder.addCase(fetchSupportDetails.fulfilled, (state, action) => {
      state.supportDetails = action.payload && action.payload?.data?.data;
      state.supportLoading = false;
    });
    builder.addCase(fetchSupportDetails.rejected, (state) => {
      state.supportLoading = false;
    });

    // Fetch complete shop details
    builder.addCase(fetchCompleteShopDetails.pending, (state) => {
      state.completeShopData = [];
    });
    builder.addCase(fetchCompleteShopDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.completeShopData = action?.payload?.data?.data;
    });
    builder.addCase(fetchCompleteShopDetails.rejected, (state) => {
      state.loading = false;
      state.completeShopData = [];
    });

    //Fetch job task progress
    builder.addCase(fetchAllJobTaskProgress.pending, () => {});
    builder.addCase(fetchAllJobTaskProgress.fulfilled, (state, action) => {
      state.loading = false;
      state.jobTaskProgressData = action?.payload?.data?.data;
    });
    builder.addCase(fetchAllJobTaskProgress.rejected, (state) => {
      state.loading = false;
      state.jobTaskProgressData = [];
    });

    //Reschedule Job
    builder.addCase(resecheduleJob.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(resecheduleJob.fulfilled, (state, action) => {
      state.loading = false;
      state.rescheduleData = action?.payload?.data?.data;
    });
    builder.addCase(resecheduleJob.rejected, (state) => {
      state.loading = false;
      state.rescheduleData = [];
    });

    // Additional detail for task details
    builder.addCase(fetchTaskDetailByJob.pending, () => {});
    builder.addCase(fetchTaskDetailByJob.fulfilled, (state, action) => {
      state.jobTaskDetailAdditional = action?.payload?.data?.data;
    });
    builder.addCase(fetchTaskDetailByJob.rejected, (state) => {
      state.jobTaskDetailAdditional = [];
    });

    // Task detail for template
    builder.addCase(fetchTaskDetailTemplate.pending, () => {});
    builder.addCase(fetchTaskDetailTemplate.fulfilled, (state, action) => {
      state.jobTaskDetailTemplate = action?.payload?.data?.data;
    });
    builder.addCase(fetchTaskDetailTemplate.rejected, (state) => {
      state.jobTaskDetailTemplate = [];
    });
    // System log
    // Task detail for template
    builder.addCase(fetchSystemTaskDetailTemplate.pending, () => {});
    builder.addCase(
      fetchSystemTaskDetailTemplate.fulfilled,
      (state, action) => {
        state.jobSystemTaskDetailTemplate =
          action?.payload?.data?.data?.records;
      }
    );
    builder.addCase(fetchSystemTaskDetailTemplate.rejected, (state) => {
      state.jobSystemTaskDetailTemplate = [];
    });
    //import jobs
    builder.addCase(importJob.pending, (state) => {
      state.jobImportData = "";
    });
    builder.addCase(importJob.fulfilled, (state, action) => {
      state.jobImportData = action?.payload?.data;
    });
    builder.addCase(importJob.rejected, (state) => {
      state.jobImportData = "";
    });

    //get Customers Feedback
    builder.addCase(getCustomersFeedback.pending, () => {});
    builder.addCase(getCustomersFeedback.fulfilled, (state, action) => {
      state.customersFeedback = action?.payload?.data;
    });
    builder.addCase(getCustomersFeedback.rejected, (state) => {
      state.customersFeedback = [];
    });

    //activity listing
    builder.addCase(fetchActivityListing.pending, (state) => {
      state.activityListLoading = true;
    });
    builder.addCase(fetchActivityListing.fulfilled, (state, action) => {
      const newRecords = action?.payload?.data?.data?.records || [];

      // Append new records to the existing list
      state.activityList =
        action?.payload?.data?.data?.pageNumber == 1
          ? newRecords
          : [...state.activityList, ...newRecords];

      state.activityListLoading = false;
    });
    builder.addCase(fetchActivityListing.rejected, (state) => {
      state.activityListLoading = false;
    });

    //Activity And Event Logs
    builder.addCase(fetchActivityAndEventLogs.pending, (state) => {
      state.activityAndEventLogsLoading = true;
    });
    builder.addCase(fetchActivityAndEventLogs.fulfilled, (state, action) => {
      const newLogsRecord = action?.payload?.data?.data?.records || [];

      //Logic to append new records
      state.activityAndEventLogs =
        action?.payload?.data?.data?.pageNumber == 1
          ? newLogsRecord
          : [...state.activityAndEventLogs, ...newLogsRecord];

      state.activityAndEventLogsLoading = false;
    });
    builder.addCase(fetchActivityAndEventLogs.rejected, (state) => {
      state.activityAndEventLogsLoading = false;
    });

    //Export jobs card
    builder.addCase(exportJobCardPDF.pending, () => {});
    builder.addCase(exportJobCardPDF.fulfilled, (state) => {
      state.jobsCardExportLoading = false;
    });

    builder.addCase(exportJobCardPDF.rejected, (state) => {
      state.jobsCardExportLoading = false;
    });

    /* Reset entire state */
    builder.addCase(resetJobState, () => {
      return {
        ...jobState,
      };
    });
  },
});

export const {
  setJobsLoading,
  setJobsSearchQuery,
  setJobsSorting,
  setJobsPagination,
  setJobsStatusFilter,
  setJobsProjectFilter,
  setJobsDateFilter,
  setJobDeliveryLoading,
  setJobDeliverySearchQuery,
  setActivityLogsSearchQuery,
  setActivityAndEventLogsSearchQuery,
  setActivityAndEventLogsFilter,
  resetActivityAndEventLogsFilter,
  resetJobState,
} = jobSlice.actions;
export default jobSlice.reducer;
