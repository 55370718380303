import { SomethingWentWrong } from "@/constants/images";
import { Component } from "react";
import { useEffect } from "react";
import CommonButton from "./common/buttons/CommonButton";
import sendErrorLog from "@/helpers/sendErrorLog";

const Fallback = () => {
  useEffect(() => {
    const handleReload = () => window.location.reload();
    window.addEventListener("popstate", handleReload);

    return () => {
      window.removeEventListener("popstate", handleReload);
    };
  }, []);

  return (
    <div className="account-pages mx-2 pt-5">
      <div className="error-main-div d-flex align-items-center ">
        <div className="d-flex flex-column flex-nowrap align-items-center m-0">
          <img src={SomethingWentWrong} alt="" className="circuit" />
          <p className="text">
            {
              "We're experiencing technical issues. Please try refreshing the page."
            }
          </p>
          <CommonButton
            className="refresh-btn"
            onClick={() => window.location.reload()}
          >
            Reload
          </CommonButton>
        </div>
      </div>
    </div>
  );
};

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    const payload = {
      message: error?.message,
      description:
        "\n\n" +
        "File Name: \n" +
        error?.fileName +
        "\n\n" +
        "Location: \n" +
        window.location.href +
        "\n\n" +
        "Component Stack: \n" +
        info?.componentStack +
        "\n\n" +
        "Stack: \n" +
        error.stack,
    };
    sendErrorLog(payload)
  }

  render() {
    if (this.state.hasError) {
      // Render fallback UI
      return <Fallback />;
    }

    // Render children if no error
    return this.props.children;
  }
}

export default ErrorBoundary;
