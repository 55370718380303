import { forwardRef, useState } from "react";
import { calenderIcon } from "@/constants/images";
import { emojiRegex } from "@/constants/ConstantConfig";
const CommonInputTextField = (
  {
    labelName,
    required,
    readOnly,
    mutedText,
    isInvalid = false,
    errorText,
    value = "",
    applyPaddingBottom = true,
    preRegistrationFlag = false,
    labelFlag = false,
    className,
    placeHolder,
    isDisabled = false,
    type = "text",
    classList = "",
    mainDiv = "",
    ...inputProps
  },
  ref
) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [charError, setCharError] = useState("");

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const handleWheel = (e) => {
    if (type === "number") {
      e.target.blur();
    }
  };

  const handleKeyPress = (event) => {
    if (type === "number") {
      const keyCode = event.which || event.keyCode;

      // Allow only digits (0-9) and prevent other key presses
      if (keyCode < 48 || keyCode > 57) {
        event.preventDefault();
      }
    }
  };

  const handlePaste = (event) => {
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData("Text");
    if (type === "number") {
      // Remove non-digit characters from the pasted data
      const sanitizedData = pastedData.replace(/[^0-9]/g, "");
      // Update the input value with the sanitized data
      document?.execCommand("insertText", false, sanitizedData);

      // Prevent the default paste behavior
      event.preventDefault();
    }
    if (type === "text") {
      const sanitizedValue = pastedData
        .replace(emojiRegex, "")
        .replace(/"/g, "");
      // Truncate pasted data to 250 characters
      const truncatedData = sanitizedValue.slice(0, 250);

      // Update the input value with the truncated data
      document.execCommand("insertText", false, truncatedData);

      // Prevent the default paste behavior
      event.preventDefault();
    }
    if (type === "password") {
      const sanitizedValue = pastedData.replace(emojiRegex, "");
      // Truncate pasted data to 250 characters
      const truncatedData = sanitizedValue.slice(0, 16);

      // Update the input value with the truncated data
      document.execCommand("insertText", false, truncatedData);

      // Prevent the default paste behavior
      event.preventDefault();
    }
  };

  const handleChange = (event) => {
    const { value } = event.target;
    const sanitizedValue = value.replace(emojiRegex, "").replace(/"/g, "");

    // Enforce character limit
    if (sanitizedValue.length > 250) {
      setCharError("Character limit exceeded. Max 250 characters allowed.");
      event.target.value = sanitizedValue.slice(0, 250); // Truncate the input value
      return;
    } else {
      event.target.value = sanitizedValue;
    }

    setCharError(""); // Reset character error
    inputProps.onChange && inputProps.onChange(event);
  };
  //To open datepicker on click on anywhere in the date field
  const handleFocus = (event) => {
    if (type === "date") {
      event.target.showPicker();
    }
  };

  return (
    <div
      className={`${
        (applyPaddingBottom || preRegistrationFlag) && !labelFlag ? "field" : ""
      } ${applyPaddingBottom || labelFlag ? "padding-field" : ""} ${mainDiv}`}
    >
      {labelName ? <label className={"label"}>{labelName}</label> : null}
      {required ? <span className="text-danger">*</span> : null}
      {mutedText ? <p className={"text-muted"}>{mutedText}</p> : null}
      <div className={`input-container position-relative ${classList}`}>
        <input
          ref={ref}
          className={`form-control  ${
            isInvalid || charError ? "invalid" : ""
          } ${className}`}
          type={
            type === "password"
              ? isPasswordVisible
                ? "text"
                : "password"
              : type
          }
          readOnly={!!readOnly}
          value={value}
          placeholder={placeHolder}
          {...inputProps}
          disabled={isDisabled}
          onWheel={handleWheel}
          onKeyPress={handleKeyPress}
          onPaste={handlePaste}
          onChange={handleChange}
          // min={moment(Date.now()).format("YYYY-MM-DD")}
          onClick={handleFocus}
        />
        {type === "password" && (
          <span
            className={`password-toggle cursor-pointer ${
              isPasswordVisible ? "visible" : ""
            }`}
            onClick={togglePasswordVisibility}
          >
            <i
              className={`fa-solid fa-eye cur_point show_password ${
                !isPasswordVisible ? "fa-eye-slash" : "fa-eye"
              }`}
            ></i>
          </span>
        )}
        {type === "date" && (
          <img
            src={calenderIcon}
            alt="calendar icon"
            className="calendar-icon cursor"
            // onClick={handleFocus}
          />
        )}
      </div>
      {(isInvalid && errorText) || charError ? (
        <label htmlFor={inputProps.name} className="error-text m-0">
          {isInvalid ? errorText : charError}
        </label>
      ) : null}
    </div>
  );
};

export default forwardRef(CommonInputTextField);
