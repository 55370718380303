import { combineReducers } from "@reduxjs/toolkit";
import dashboardReducer from "@/modules/dashboard/slice";
import loginReducer from "@/modules/login/slice/loginSlice";
import adminReducer from "@/modules/admins/slice/adminSlice";
import serviceReducer from "@/modules/services/slice/serviceSlice";
import skillReducer from "@/modules/skills/slice/skillSlice";
import companiesReducer from "@/modules/companies/slice/companiesSlice";
import productsReducer from "@/modules/products/slice/productsSlice";
import engineerReducer from "@/modules/engineer/slice/engineerSlice";
import projectsReducer from "@/modules/projects/slice/ProjectSlice";
import profileReducer from "@/modules/profile/slice/ProfileSlice";
import goodReceiptReducer from "@/modules/goodsReceipt/slice/goodReceiptSlice";
import templateReducer from "@/modules/templates/slice/templateSlice";
import stockReducer from "@/modules/stockMovement/slice/stockSlice";
import suppliersReducer from "@/modules/suppliers/slices/SupplierSlice";
import supplierContactReducer from "@/modules/suppliers/slices/SupplierContactSlice";
import permissionProfileReducer from "@/modules/permissionProfile/slice/permissionProfile.slice";
import customerShopReducer from "@/modules/customers&shops/slice/customerShopSlice";
import customerReducer from "@/modules/customers&shops/slice/customerSlice";
import deliveryNotesReducer from "@/modules/deliveryNotes/slice/deliveryNotesSlice";
import jobsReducer from "@/modules/jobs/slice/jobSlice";
import additionalDetailsMasterReducer from "@/modules/additionalDetailsMaster/slice/additionalDetailsMasterSlice";
import faqReducer from "@/modules/faq/slice/faqSlice";
import activityLogReducer from "@/modules/activityLogs/slice/activityLogSlice"

// Root reducer that resets all reducers to their initial state when the RESET_STORE action is dispatched
const appReducer = combineReducers({
  dashboard: dashboardReducer,
  login: loginReducer,
  admin: adminReducer,
  service: serviceReducer,
  skills: skillReducer,
  companies: companiesReducer,
  products: productsReducer,
  engineer: engineerReducer,
  projects: projectsReducer,
  profile: profileReducer,
  goodReceipt: goodReceiptReducer,
  templates: templateReducer,
  stockMovement: stockReducer,
  suppliers: suppliersReducer,
  supplierContact: supplierContactReducer,
  permissionProfile: permissionProfileReducer,
  customer: customerReducer,
  customerShop: customerShopReducer,
  deliveryNotes: deliveryNotesReducer,
  jobs: jobsReducer,
  additionalDetailsMaster: additionalDetailsMasterReducer,
  faq: faqReducer,
  activityLog:activityLogReducer
});

const rootReducer = (state, action) => {
  if (action.type === "RESET_STORE") {
    state = undefined; // Reset state to undefined, which will reset all reducers to their initial state
  }
  return appReducer(state, action);
};

export default rootReducer;
