import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { GrClose } from "react-icons/gr";
import { nisystBlue } from "@/constants/images";
import SidebarMenuItem from "./SidebarMenuItem";
import { navLinks } from "@/constants/ConstantConfig";
import routesConstants from "@/routes/routesConstants";
import { resetJobState } from "@/modules/jobs/slice/jobSlice";
import usePermissions from "@/components/hooks/usePermissions";
import { resetSkillState } from "@/modules/skills/slice/skillSlice";
import { resetAdminState } from "@/modules/admins/slice/adminSlice";
import { CheckPermission } from "@/components/common/commonFunctions";
import { resetServiceState } from "@/modules/services/slice/serviceSlice";
import { resetProjectState } from "@/modules/projects/slice/ProjectSlice";
import { resetProductState } from "@/modules/products/slice/productsSlice";
import { resetStockState } from "@/modules/stockMovement/slice/stockSlice";
import { resetEngineerState } from "@/modules/engineer/slice/engineerSlice";
import { resetTemplateState } from "@/modules/templates/slice/templateSlice";
import { resetCompanyState } from "@/modules/companies/slice/companiesSlice";
import { resetSupplierState } from "@/modules/suppliers/slices/SupplierSlice";
import { resetCustomerState } from "@/modules/customers&shops/slice/customerSlice";
import { resetGoodReceiptState } from "@/modules/goodsReceipt/slice/goodReceiptSlice";
import { resetDeliveryNoteState } from "@/modules/deliveryNotes/slice/deliveryNotesSlice";
import { resetAllCustomerShop } from "@/modules/customers&shops/slice/customerShopSlice";
import { resetPermissionState } from "@/modules/permissionProfile/slice/permissionProfile.slice";
import { resetActivityLogState } from "@/modules/activityLogs/slice/activityLogSlice";

const Sidebar = ({ isOpen, toggleSidebar, setIsOpen }) => {
  const { pathname } = useLocation();
  const sidebarRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { fetchPermissions } = usePermissions();
  const { profileDetail, adminPermissions } = useSelector(
    (state) => state.profile
  );

  const pathToActionMap = {
    [routesConstants.PRODUCTS]: resetProductState,
    [routesConstants.SUPPLIER]: resetSupplierState,
    [routesConstants.GOODS_RECEIPT]: resetGoodReceiptState,
    [routesConstants.STOCK_MOVEMENT]: resetStockState,
    [routesConstants.SERVICES]: resetServiceState,
    [routesConstants.SKILLS]: resetSkillState,
    [routesConstants.TEMPLATES]: resetTemplateState,
    [routesConstants.PROJECTS]: resetProjectState,
    [routesConstants.COMPANIES]: resetCompanyState,
    [routesConstants.ENGINEERS]: resetEngineerState,
    [routesConstants.DELIVERY_NOTES]: resetDeliveryNoteState,
    [routesConstants.ADMINS]: resetAdminState,
    [routesConstants.PERMISSIONS_PROFILES]: resetPermissionState,
    [routesConstants.CUSTOMERS]: resetCustomerState,
    [routesConstants.JOBS]: resetJobState,
    [routesConstants.CUSTOMERS]: resetAllCustomerShop,
    [routesConstants.ACTIVITY_LOG]: resetActivityLogState,
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        sidebarRef?.current &&
        !sidebarRef?.current.contains(event?.target) &&
        window?.innerWidth < 1200
      ) {
        setIsOpen(false);
      }
    };
    document?.addEventListener("mousedown", handleClickOutside);
    return () => document?.removeEventListener("mousedown", handleClickOutside);
  }, [setIsOpen]);

  const handleItemClick = (path) => {
    try {
      if (pathname !== path) {
        const resetAction = pathToActionMap[path];
        if (resetAction) {
          dispatch(resetAction());
        }
        // Call fetchPermissions when navigating to a new path
        fetchPermissions();
      }
      navigate(path);
      if (window?.innerWidth < 1200) {
        setIsOpen(false);
      }
    } catch (error) {
      console.error(`Error navigating to ${path}:`, error);
    }
  };

  return (
    <div
      ref={sidebarRef}
      className={`sidebar ${isOpen ? "open smallScSide" : "closed"}`}
    >
      <div className="nisyst_logo set_content justify-content-center px-2 py-2">
        <img
          src={isOpen ? nisystBlue : "/favicon1.png"}
          alt="Logo"
          onClick={toggleSidebar}
        />
        <div
          className="gr-close-icon set_content p-2 sb_close"
          onClick={() => setIsOpen(false)}
        >
          <GrClose size={20} />
        </div>
      </div>
      <nav className="menuItems_list">
        <ul>
          {navLinks?.map((link) => {
            const permission = CheckPermission(
              link?.menuId,
              "view",
              adminPermissions?.permissionProfileMenuList
            );
            return (
              !!link &&
              link?.roles?.includes(profileDetail?.userTypeDigit) &&
              permission && (
                <SidebarMenuItem
                  key={link?.name}
                  pathname={pathname}
                  link={link}
                  handleItemClick={handleItemClick}
                  isOpen={isOpen}
                />
              )
            );
          })}
        </ul>
      </nav>
    </div>
  );
};
export default Sidebar;
