import { toast } from "react-toastify";
import { axiosNisystAdmin } from "@/services/api";
import {
    GET_ALL_ACTIVITY_LOG,
    GET_MENU_ACTIVITY_LOG,
} from "@/services/url";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { somethingWentWrong } from "@/constants/SchemaValidation";

const activityLogInitialState = {
  loading: true,
  activityLogList: [],
  filters: {
    searchQuery: "",
    pagination: {
      pageIndex: 0,
      pageSize: 10,
    },
    status:  { value:0, label: "All" },
  },
  menuLoading:false,
  activityLogMenu:[]
};

//Get all activity log list
export const getActivityLogList = createAsyncThunk(
  `activityLog/getActivityLogList`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.post(
        GET_ALL_ACTIVITY_LOG,
        payload
      );
      return response?.data;
    } catch (err) {
      const errorMessage = err?.response?.data?.message || err.message;
      toast.error(errorMessage || somethingWentWrong);
      return thunkAPI.rejectWithValue({
        statusCode: err?.response?.data?.statusCode,
        message: errorMessage,
      });
    }
  }
);

// master data 
export const getActivityLogMenu = createAsyncThunk(
  `activityLog/getActivityLogMenu`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.get(
        GET_MENU_ACTIVITY_LOG,
        // payload
      );
      return response?.data;
    } catch (err) {
      const errorMessage = err?.response?.data?.message || err.message;
      toast.error(errorMessage || somethingWentWrong);
      return thunkAPI.rejectWithValue({
        statusCode: err?.response?.data?.statusCode,
        message: errorMessage,
      });
    }
  }
);

const activityLogSlice = createSlice({
  name: "activityLogSlice",
  initialState: activityLogInitialState,
  reducers: {
    setActivityLogLoading: (state, action) => {
      state.loading = action.payload;
    },
    setActivityLogSearchQuery: (state, action) => {
      state.filters.searchQuery = action.payload;
    },
    setActivityLogPagination: (state, action) => {
      state.filters.pagination = action.payload;
    },
    setActivityLogStatus: (state, action) => {
      state.filters.status = action.payload;
    },
    resetActivityLogState: () => {
      return {
        ...activityLogInitialState,
      };
    },
  },
  extraReducers: (builder) => {
    //Get all additional Details list
    builder.addCase(getActivityLogList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getActivityLogList.fulfilled, (state, action) => {
      state.loading = false;
      state.activityLogList = action.payload?.data;
    });
    builder.addCase(getActivityLogList.rejected, (state) => {
      state.loading = false;
      state.activityLogList = [];
    });
    //Get master data 
    builder.addCase(getActivityLogMenu.pending, (state) => {
      state.menuLoading = true;
    });
    builder.addCase(getActivityLogMenu.fulfilled, (state, action) => {
      state.menuLoading = false;
      state.activityLogMenu = action.payload?.data;
    });
    builder.addCase(getActivityLogMenu.rejected, (state) => {
      state.menuLoading = false;
      state.activityLogMenu = [];
    });
    /* Reset entire state */
    builder.addCase(resetActivityLogState, () => {
      return {
        ...activityLogInitialState,
      };
    });
  },
});

export const {
 setActivityLogLoading,
  setActivityLogPagination,
  resetActivityLogState,
  setActivityLogSearchQuery,
  setActivityLogStatus
} = activityLogSlice.actions;

export default activityLogSlice.reducer;
