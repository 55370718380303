import moment from "moment-timezone";
import {
  commonPermissionMessages,
  FieldType,
} from "@/constants/ConstantConfig";
import {
  ClickAwayListener,
  styled,
  Tooltip,
  tooltipClasses,
} from "@mui/material";

// Templates
export const CustomTooltip = styled(({ className, onClose, ...props }) => (
  <ClickAwayListener onClickAway={onClose}>
    <Tooltip {...props} classes={{ popper: className }} />
  </ClickAwayListener>
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#ffffff",
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    border: "1px solid #797979",
    borderRadius: "20px",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#797979",
  },
}));

export const GetFieldTypeEnum = (taskType) => {
  switch (taskType) {
    case "Single Checkbox":
      return FieldType.SingleCheckbox;
    case "Single Checkbox with text":
      return FieldType.SingleTickBoxWithText;
    case "Single Checkbox with Number":
      return FieldType.SingleTickBoxWithNumber;
    case "Multiple Checkbox":
      return FieldType.MultipleCheckbox;
    case "Radio Button (Any One)":
      return FieldType["Radio Button (Any One)"];
    case "Dropdown":
      return FieldType.Dropdown;
    case "Images":
      return FieldType.Images;
    case "Date Picker":
      return FieldType["Date Picker"];
    default:
      return 0;
  }
};

export const GetFieldsForTaskType = (taskType) => {
  switch (taskType) {
    case "Single Checkbox":
      return [
        {
          type: "single_checkbox",
          name: "taskTitle",
          label: "Task Title",
          placeholder: "Enter task title",
          required: true,
          value: "",
          readOnly: false,
          className: "taskInputField",
        },
      ];
    case "Single Checkbox with text":
      return [
        {
          type: "text",
          name: "taskTitle",
          label: "Task Title",
          placeholder: "Enter task title",
          required: true,
          value: "",
          readOnly: false,
          className: "taskInputField",
        },
        {
          type: "text",
          name: "answer",
          label: "Answer",
          placeholder: "Write your text here",
          required: false,
          value: "",
          readOnly: true,
          className: "taskInputField disabled_task_field",
          addCheckBox: true,
        },
      ];
    case "Single Checkbox with Number":
      return [
        {
          type: "text",
          name: "taskTitle",
          label: "Task Title",
          placeholder: "Enter task title",
          required: true,
          value: "",
          readOnly: false,
          className: "taskInputField",
        },
        {
          type: "select_number",
          name: "answer",
          label: "Answer",
          placeholder: "",
          required: true,
          value: "",
          readOnly: false,
          className: "disabled_task_field",
        },
      ];
    case "Multiple Checkbox":
      return [
        {
          type: "text",
          name: "taskTitle",
          label: "Task Title",
          placeholder: "Enter task title",
          required: true,
          value: "",
          readOnly: false,
          className: "taskInputField",
        },
        {
          type: "checkbox",
          name: "multiSelect",
          label: "",
          options: ["Option 1", "Option 2", "Option 3"],
          value: [],
        },
      ];
    case "Radio Button (Any One)":
      return [
        {
          type: "text",
          name: "taskTitle",
          label: "Task Title",
          placeholder: "Enter task title",
          required: true,
          value: "",
          readOnly: false,
          className: "taskInputField",
        },
        {
          type: "radio",
          name: "radioOptions",
          label: "",
          options: ["Option 1", "Option 2"],
          value: "",
        },
      ];
    case "Dropdown":
      return [
        {
          type: "text",
          name: "taskTitle",
          label: "Task Title",
          placeholder: "Enter task title",
          required: true,
          value: "",
          readOnly: false,
          className: "taskInputField",
        },
        {
          type: "dropdown",
          name: "dropdown selection",
          label: "",
          options: ["Option 1", "Option 2", "Option 3"],
          value: [],
        },
      ];
    case "Images":
      return [
        {
          type: "text",
          name: "taskTitle",
          label: "Task Title",
          placeholder: "Enter task title",
          required: true,
          value: "",
          readOnly: false,
          className: "taskInputField",
        },
        {
          type: "upload",
          name: "Upload a image",
          label: "Upload a image",
          placeholder: "",
          required: false,
          value: "",
          readOnly: true,
          className: "taskInputField disabled_task_field",
        },
      ];
    case "Date Picker":
      return [
        {
          type: "text",
          name: "taskTitle",
          label: "Task Title",
          placeholder: "Enter task title",
          required: true,
          value: "",
          readOnly: false,
          className: "taskInputField",
        },
        {
          type: "date",
          name: "date",
          label: "Date",
          placeholder: "",
          required: false,
          value: "",
          readOnly: true,
          className: "taskInputField disabled_task_field fixed_text_color",
          classList: "w-25",
        },
      ];
    default:
      return [];
  }
};

// Role wise handling
export function CheckPermission(menuId, action, permissions) {
  const defaultPermissions = {
    view: true,
    add: true,
    edit: true,
    delete: true,
    block: true,
  };
  if (!menuId || !permissions?.length) {
    return action === "all" ? defaultPermissions : true;
  }
  const menu = permissions.find((item) => item.menuId === menuId);
  if (!menu) {
    return action === "all" ? defaultPermissions : true;
  }
  const permissionObject = {
    view: menu?.viewPermission ?? true,
    add: menu?.addPermission ?? true,
    edit: menu?.editPermission ?? true,
    delete: menu?.deletePermission ?? true,
    block: menu?.blockPermission ?? true,
  };
  return action === "all" ? permissionObject : permissionObject[action] ?? true;
}

//permissions
export const GetAllPermissionMessages = (menuName, menu) => ({
  hide: commonPermissionMessages.hide(menuName),
  view: commonPermissionMessages.view(menu),
  add: commonPermissionMessages.add(menu),
  edit: commonPermissionMessages.edit(menu),
  delete: commonPermissionMessages.delete(menu),
  block: commonPermissionMessages.block(menu),
});

//Job
export const GetNameById = (array, idKey, idValue, nameKey) => {
  const item = array?.find((element) => {
    const item = element[idKey]?.toLowerCase();
    return item === idValue?.toLowerCase();
  });
  return item ? item[nameKey] : "";
};

export const GetObjectById = (array, idKey, idValue) => {
  const item = array?.find((element) => {
    const item = element[idKey]?.toLowerCase();
    return item === idValue?.toLowerCase();
  });
  return item ? item : {};
};

export const ValidateStep0Fields = async ({ values, validationSchemas }) => {
  try {
    await validationSchemas[0]?.validate(values, { abortEarly: false });
    return false;
  } catch (err) {
    return true;
  }
};

// Dashboard - Top 10 Engineers
export const ConvertMinutesToHours = (minutes) => {
  const hours = Math.floor(minutes / 60); // Calculate whole hours
  const remainingMinutes = Math.round(minutes % 60); // Round remaining minutes
  return `${hours} h ${remainingMinutes} m`;
};

// Add/Edit Job check is same or after date
export const IsDateGreaterOrEqual = (checkDate) => {
  if (!checkDate) return false;
  const timezone = moment?.tz?.guess();
  const todayStart = moment?.tz(timezone)?.startOf("day");
  const dateStart = moment
    ?.tz(checkDate, "DD/MM/YYYY", timezone)
    .startOf("day");
  return dateStart?.isValid() && dateStart?.isSameOrAfter(todayStart, "day");
};

//Dashboard duration check
export const IsDelayed = (takenDuration, serviceDuration) => {
  const parseDuration = (durationStr) =>
    durationStr
      .split(" ")
      .reduce(
        (total, curr, i, arr) =>
          curr.includes("Hour")
            ? total + parseInt(arr[i - 1] || 0) * 60
            : curr.includes("Minute")
            ? total + parseInt(arr[i - 1] || 0)
            : total,
        0
      );
  const takenMinutes = parseDuration(takenDuration);
  const serviceMinutes = parseDuration(serviceDuration);
  return takenMinutes > serviceMinutes;
};
