// Use lazy loaded pages
import { lazy } from "react";

//Authentication
export const Login = lazy(() => import("@/modules/login/pages"));
export const TwoFactorAuthentication = lazy(() =>
  import("@/modules/login/components/TwoFactorAuthentication")
);
export const ForgotPassword = lazy(() =>
  import("@/modules/login/components/ForgotPassword")
);
export const ResetPassword = lazy(() =>
  import("@/modules/login/components/ResetPassword")
);

//Dashboard
export const Dashboard = lazy(() => import("@/modules/dashboard/pages"));

//My Profile
export const Profile = lazy(() => import("@/modules/profile/pages"));
export const ChangePassword = lazy(() =>
  import("@/modules/login/components/ChangePassword")
);

//Jobs
export const Jobs = lazy(() => import("@/modules/jobs/pages"));
export const AddEditJobs = lazy(() =>
  import("@/modules/jobs/components/addEditJob/AddEditJob")
);
export const ViewJobs = lazy(() => import("@/modules/jobs/pages/ViewJobs"));

//Delivery Notes
export const DeliveryNotes = lazy(() =>
  import("@/modules/deliveryNotes/pages")
);
export const SearchDeliveryNotesItems = lazy(() =>
  import("@/modules/deliveryNotes/pages/SearchDeliveryNotesItems")
);

export const AddEditDeliveryNotes = lazy(() =>
  import("@/modules/deliveryNotes/pages/AddEditDeliveryNotes")
);

export const ViewDeliveryNotes = lazy(() =>
  import("@/modules/deliveryNotes/pages/ViewDeliveryNotes")
);

//Products
export const Products = lazy(() => import("@/modules/products/pages"));
export const ViewProductDetails = lazy(() =>
  import("@/modules/products/components/ViewProductDetails")
);
export const AddEditProduct = lazy(() =>
  import("@/modules/products/components/AddEditProducts")
);

//Suppliers
export const Suppliers = lazy(() => import("@/modules/suppliers/pages"));
export const AddEditSupplier = lazy(() =>
  import("@/modules/suppliers/pages/AddEditSupplier")
);
export const ViewSupplier = lazy(() =>
  import("@/modules/suppliers/pages/ViewSupplier")
);

//Goods Receipt
export const GoodReceipt = lazy(() => import("@/modules/goodsReceipt/pages"));
export const AddEditGoodReceipt = lazy(() =>
  import("@/modules/goodsReceipt/pages/AddEditGoodReceipt")
);

// Stock Movement
export const StockMovement = lazy(() =>
  import("@/modules/stockMovement/pages")
);
export const AddEditStockMovement = lazy(() =>
  import("@/modules/stockMovement/pages/AddEditStockMovement")
);

//Customers
export const Customers = lazy(() => import("@/modules/customers&shops/pages"));
export const CustomersPage = lazy(() =>
  import("@/modules/customers&shops/pages/CustomersPage")
);
export const ViewCustomerPage = lazy(() =>
  import("@/modules/customers&shops/pages/ViewCustomer")
);
export const AddShops = lazy(() =>
  import("@/modules/customers&shops/pages/shop/AddShops")
);
export const ViewShops = lazy(() =>
  import("@/modules/customers&shops/pages/shop/ViewShops")
);

//Services
export const Service = lazy(() => import("@/modules/services/pages"));
export const ViewService = lazy(() =>
  import("@/modules/services/pages/ViewService")
);

//Skills
export const Skill = lazy(() => import("@/modules/skills/pages"));
export const ViewSkill = lazy(() => import("@/modules/skills/pages/ViewSkill"));

//Additional Details Master
export const AdditionalDetailsMaster = lazy(() =>
  import("@/modules/additionalDetailsMaster/pages")
);

//Templates
export const Templates = lazy(() => import("@/modules/templates/pages"));
export const AddEditTemplate = lazy(() =>
  import("@/modules/templates/components/addEditTemplates/AddEditTemplate")
);
export const ViewTemplate = lazy(() =>
  import("@/modules/templates/pages/ViewTaskTemplate")
);

//Projects
export const Projects = lazy(() => import("@/modules/projects/pages"));
export const ProjectView = lazy(() =>
  import("@/modules/projects/component/ViewProject")
);

// Companies
export const Companies = lazy(() => import("@/modules/companies/pages"));
export const AddEditCompanies = lazy(() =>
  import("@/modules/companies/components/AddEditCompanies")
);
export const ViewCompany = lazy(() =>
  import("@/modules/companies/components/ViewCompany")
);
export const ViewCompanyContactDetails = lazy(() =>
  import("@/modules/companies/components/ViewContactDetails")
);

//Engineers
export const Engineers = lazy(() => import("@/modules/engineer/pages"));
export const EngineerDetails = lazy(() =>
  import("@/modules/engineer/pages/EngineerDetail")
);

//Admins
export const Admins = lazy(() => import("@/modules/admins/pages"));
export const ViewAdmin = lazy(() => import("@/modules/admins/pages/ViewAdmin"));

//Permission Profile
export const PermissionProfile = lazy(() =>
  import("@/modules/permissionProfile/pages/index")
);
export const AddEditPermission = lazy(() =>
  import("@/modules/permissionProfile/pages/AddEditPermission")
);
export const ViewPermission = lazy(() =>
  import("@/modules/permissionProfile/pages/ViewPermission")
);

//FAQ's
export const FAQs = lazy(() => import("@/modules/faq/pages"));


//About us
export const AboutUs = lazy(() => import("@/modules/common/AboutUs"));

//Activity Log
export const ActivityLog = lazy(() => import("@/modules/activityLogs/pages"));